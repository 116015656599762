import { FeatureControlActionTypes } from './actionTypes'
import { getNations, setSelectedNation, getRegions, setSelectedRegion, getAreas, setSelectedArea, getTerritories, setSelectedTerritory } from './navActions'

// Set isNationOn
export function setIsNationOn(isNationOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_NATION_ON, payload: isNationOn })
  }
}

// Set isRegionOn
export function setIsRegionOn(isRegionOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_REGION_ON, payload: isRegionOn })
  }
}

// Set isAreaOn
export function setIsAreaOn(isAreaOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_AREA_ON, payload: isAreaOn })
  }
}

// Set isTerritoryOn
export function setIsTerritoryOn(isTerritoryOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_TERRITORY_ON, payload: isTerritoryOn })
  }
}

// Set isTownOn
export function setIsTownOn(isTownOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_TOWN_ON, payload: isTownOn })
  }
}

// Set isRouteOn
export function setIsRouteOn(isRouteOn) {
  return dispatch => {
    dispatch({ type: FeatureControlActionTypes.SET_IS_ROUTE_ON, payload: isRouteOn })
  }
}

// Set Feature Controls Based On User
export function setFeatureControls(user) {
  return (dispatch, getState) => {
    // If User Invalid
    if(!user) {
      return
    }

    // Territory Manager
    if(user.designation === 'TM' && user.territory_id) {
      dispatch( setIsNationOn(false) )
      dispatch( setIsRegionOn(false) )
      dispatch( setIsAreaOn(false) )
      dispatch( setIsTerritoryOn(false) )

      const startDate = getState()?.stat?.startDate ?? ''
      const endDate = getState()?.stat?.endDate ?? ''
      const selectedBrand = getState()?.stat?.selectedBrand ?? null
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const selectedSku = getState()?.stat?.selectedSku ?? null
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Get Territories Data from API
      dispatch( getTerritories(null, startDate, endDate, brand, sku, user.territory_id) )

      const intervalId = setInterval(() => {
        const territories = getState()?.nav?.territories ?? []
        if(territories?.length) {
          clearInterval(intervalId)

          const selectedTerritory = territories.find(t => t.id === user.territory_id) ?? null
          dispatch( setSelectedTerritory(selectedTerritory) )
        }
      }, 1000)

      return
    }

    // Area Manager
    if(user.designation === 'AM' && user.area_id) {
      dispatch( setIsNationOn(false) )
      dispatch( setIsRegionOn(false) )
      dispatch( setIsAreaOn(false) )

      const startDate = getState()?.stat?.startDate ?? ''
      const endDate = getState()?.stat?.endDate ?? ''
      const selectedBrand = getState()?.stat?.selectedBrand ?? null
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const selectedSku = getState()?.stat?.selectedSku ?? null
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Get Areas Data from API
      dispatch( getAreas(null, startDate, endDate, brand, sku, user.area_id) )

      const intervalId = setInterval(() => {
        const areas = getState()?.nav?.areas ?? []
        if(areas?.length) {
          clearInterval(intervalId)

          const selectedArea = areas.find(a => a.id === user.area_id) ?? null
          dispatch( setSelectedArea(selectedArea) )
        }
      }, 1000)

      return
    }

    // Region Manager
    if(user.designation === 'RM' && user.region_id) {
      dispatch( setIsNationOn(false) )
      dispatch( setIsRegionOn(false) )

      const startDate = getState()?.stat?.startDate ?? ''
      const endDate = getState()?.stat?.endDate ?? ''
      const selectedBrand = getState()?.stat?.selectedBrand ?? null
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const selectedSku = getState()?.stat?.selectedSku ?? null
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Get Regions Data from API
      dispatch( getRegions(null, startDate, endDate, brand, sku, user.region_id) )

      const intervalId = setInterval(() => {
        const regions = getState()?.nav?.regions ?? []
        if(regions?.length) {
          clearInterval(intervalId)

          const selectedRegion = regions.find(r => r.id === user.region_id) ?? null
          dispatch( setSelectedRegion(selectedRegion) )
        }
      }, 1000)

      return
    }

    // Country Manager
    if(user.designation === 'CM' && user.nation_id) {
      dispatch( setIsNationOn(false) )

      const startDate = getState()?.stat?.startDate ?? ''
      const endDate = getState()?.stat?.endDate ?? ''
      const selectedBrand = getState()?.stat?.selectedBrand ?? null
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const selectedSku = getState()?.stat?.selectedSku ?? null
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Get Nations Data from API
      dispatch( getNations(startDate, endDate, brand, sku, user.nation_id) )

      const intervalId = setInterval(() => {
        const nations = getState()?.nav?.nations ?? []
        if(nations?.length) {
          clearInterval(intervalId)

          const selectedNation = nations.find(n => n.id === user.nation_id) ?? null
          dispatch( setSelectedNation(selectedNation) )
        }
      }, 1000)

      return
    }
  }
}