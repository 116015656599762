import { FeatureControlActionTypes } from '../actions/actionTypes'

const initialState = {
  isNationOn: true,
  isRegionOn: true,
  isAreaOn: true,
  isTerritoryOn: true,
  isTownOn: true,
  isRouteOn: true
}

const featureControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case FeatureControlActionTypes.SET_IS_NATION_ON:
      return {
        ...state,
        isNationOn: action.payload
      }
    case FeatureControlActionTypes.SET_IS_REGION_ON:
      return {
        ...state,
        isRegionOn: action.payload
      }
    case FeatureControlActionTypes.SET_IS_AREA_ON:
      return {
        ...state,
        isAreaOn: action.payload
      }
    case FeatureControlActionTypes.SET_IS_TERRITORY_ON:
      return {
        ...state,
        isTerritoryOn: action.payload
      }
    case FeatureControlActionTypes.SET_IS_TOWN_ON:
      return {
        ...state,
        isTownOn: action.payload
      }
    case FeatureControlActionTypes.SET_IS_ROUTE_ON:
      return {
        ...state,
        isRouteOn: action.payload
      }
    default:
      return state
  }
}

export default featureControlReducer