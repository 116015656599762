import { createStore, applyMiddleware, compose } from 'redux'
import { taskMiddleware } from 'react-palm/tasks'
import {thunk} from 'redux-thunk'
import rootReducer from './reducers/rootReducer'
import  { enhanceReduxMiddleware } from '@kepler.gl/reducers'

const initialState = {};
const middlewares = enhanceReduxMiddleware([
  thunk,
  taskMiddleware
]);
const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(
  rootReducer,
  initialState,
  compose(...enhancers)
);

export default store