import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsSrRouteOn, getOptimizedSrRoute } from '../../redux/actions/navActions'
import { clearSrRouteFromMap, setOutletsLayerVisibility } from '../../redux/actions/mapActions'

class RouteControl extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { dispatch, isSrRouteOn, outletsDataset, selectedRoute } = this.props

    // If isSrRouteOn, Route or Outlets changes
    if(prevProps?.isSrRouteOn !== isSrRouteOn || prevProps?.selectedRoute?.value !== selectedRoute?.value || !_isEqual(prevProps?.outletsDataset?.filteredIndexForDomain, outletsDataset?.filteredIndexForDomain)) {
      if(isSrRouteOn && selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && outletsDataset?.filteredIndexForDomain?.length) {
        // Get Optimized SR Route
        dispatch( getOptimizedSrRoute() )
        dispatch( setOutletsLayerVisibility(isSrRouteOn) )

      } else {
        // Clear Previous SR Route Datasets
        dispatch( clearSrRouteFromMap() )
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch( clearSrRouteFromMap() )
    dispatch( setIsSrRouteOn(false) )
  }

  // On Route Control Change
  _onRouteControlChange = (e, value) => {
    const { dispatch } = this.props
    dispatch( setIsSrRouteOn(value) )
  }
  
  render() {
    const { isSrRouteOn } = this.props

    return (
      <Box>
        <StyledSwitch
          label='Optimized SO Route'
          checked={ isSrRouteOn }
          value={ isSrRouteOn }
          onChange={ this._onRouteControlChange }
        />
      </Box>
    )
  }
}

// Prop Types
RouteControl.propTypes = {
  isSrRouteOn: PropTypes.bool,
  outletsDataset: PropTypes.object,
  selectedRoute: PropTypes.object,
  dispatch: PropTypes.func
}

RouteControl.defaultProps = {
  isSrRouteOn: false,
  outletsDataset: {},
  selectedRoute: null,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isSrRouteOn: state?.nav?.isSrRouteOn ?? false,
  outletsDataset: state?.keplerGl?.map?.visState?.datasets[ OUTLETS.DATA_ID ] ?? {},
  selectedRoute: state?.nav?.selectedRoute ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(RouteControl)