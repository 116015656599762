import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsRoutesLayerVisible } from '../../redux/actions/navActions'
import { setRoutesLayerVisibility } from '../../redux/actions/mapActions'

const RoutesLayerVisibilityControl = ({ dispatch, selectedTown, isRoutesLayerVisible }) => {
  useEffect(() => {
    dispatch( setIsRoutesLayerVisible(true) )
    dispatch( setRoutesLayerVisibility(true) )
    
    return () => {
      dispatch( setIsRoutesLayerVisible(true) )
      dispatch( setRoutesLayerVisibility(true) )
    }
  }, [ dispatch ])

  useEffect(() => {
    dispatch( setRoutesLayerVisibility(isRoutesLayerVisible) )
  }, [ dispatch, isRoutesLayerVisible ])

  const _onRoutesLayerVisibilityControlChange = (e, value) => {
    dispatch( setIsRoutesLayerVisible(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Routes Layer'
        checked={ isRoutesLayerVisible }
        value={ isRoutesLayerVisible }
        onChange={ _onRoutesLayerVisibilityControlChange }
      />
    </Box>
  )
}

// Prop Types
RoutesLayerVisibilityControl.propTypes = {
  selectedTown: PropTypes.object,
  isRoutesLayerVisible: PropTypes.bool,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  isRoutesLayerVisible: state?.nav?.isRoutesLayerVisible ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(RoutesLayerVisibilityControl)