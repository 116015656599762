// BASE URL
const API_BASE_URL = window?._env_?.REACT_APP_API_BASE_URL
const GEO_SERVER_BASE_URL = window?._env_?.REACT_APP_GEO_SERVER_BASE_URL
const AUTH_BASE_URL =  window?._env_?.REACT_APP_AUTH_BASE_URL
const TRACE_BASE_URL = window?._env_?.REACT_APP_TRACE_BASE_URL

// MAP
export const MAP = {
  API_KEY: window?._env_?.REACT_APP_MAP_API_KEY
}

// AUTH
export const AUTH = {
  LOGIN_API: `${ API_BASE_URL }/api/login`,
  GET_USER_API: `${ API_BASE_URL }/api/auth/user`,
  TRACE_LOGIN_API: `${ TRACE_BASE_URL }/api/v1/dashboard/login`,
  TRACE_LOGIN_USER: window?._env_?.REACT_APP_TRACE_LOGIN_USER ?? '',
  TRACE_LOGIN_PASSWORD: window?._env_?.REACT_APP_TRACE_LOGIN_PASSWORD ?? ''
}

// API
export const API = {
  GET_NATIONS: `${ API_BASE_URL }/api/v2/nation`,
  GET_REGIONS: `${ API_BASE_URL }/api/v2/region`,
  GET_AREAS: `${ API_BASE_URL }/api/v2/area`,
  GET_TERRITORIES: `${ API_BASE_URL }/api/v2/territory`,
  GET_TOWNS: `${ API_BASE_URL }/api/v2/town`,
  GET_ROUTES: `${ API_BASE_URL }/api/v2/route`,
  GET_OUTLETS: `${ API_BASE_URL }/api/v2/outlet`,
  GET_BRANDS: `${ API_BASE_URL }/api/v2/brand`,
  GET_SKU: `${ API_BASE_URL }/api/v2/sku`,
  GET_OUTLET_STATS: `${ API_BASE_URL }/api/v2/outlet-stats`,
  GET_ROUTE_STATS: `${ API_BASE_URL }/api/v2/route-stats`,
  GET_TOWN_STATS: `${ API_BASE_URL }/api/v2/town-stats`,
  GET_TERRITORY_STATS: `${ API_BASE_URL }/api/v2/territory-stats`,
  GET_AREA_STATS: `${ API_BASE_URL }/api/v2/area-stats`,
  GET_REGION_STATS: `${ API_BASE_URL }/api/v2/region-stats`,
  GET_NATION_STATS: `${ API_BASE_URL }/api/v2/nation-stats`,
  GET_SR_LIST: `${ API_BASE_URL }/api/v2/sr`,
  GET_SR_BY_ID: `${ API_BASE_URL }/api/sr-by-id`,
  GET_SR_STATS: `${ API_BASE_URL }/api/v2/sr-stats`,
  GET_DH_LIST: `${ API_BASE_URL }/api/v2/dh`,
  GET_DH_STATS: `${ API_BASE_URL }/api/v2/dh-stats`,
  GET_CHANNELS: `${ API_BASE_URL }/api/v2/channel`,
  GET_BKOI_OUTLETS: `${ API_BASE_URL }/api/bkoi-outlets`,
  GET_FIXED_OUTLETS: `${ API_BASE_URL }/api/fixed-outlets`,
  GET_FIXED_ROUTES: `${ API_BASE_URL }/api/fixed-routes`,
  GET_BKOI_CLUSTERS: `${ API_BASE_URL }/api/bkoi-clusters`,
  GET_OPTIMIZED_ROUTE: `${ GEO_SERVER_BASE_URL }/vh/route`,
  REVERSE_GEO_WITHOUT_AUTH: `${ AUTH_BASE_URL }/reverse/without/auth`
}

// REGIONS
export const NATIONS = {
  DATA_ID: 'nations',
  DATA_LABEL: 'Nations',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ]
}

// REGIONS
export const REGIONS = {
  DATA_ID: 'regions',
  DATA_LABEL: 'Regions',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ]
}

// AREAS
export const AREAS = {
  DATA_ID: 'areas',
  DATA_LABEL: 'Areas',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ]
}

// TERRITORIES
export const TERRITORIES = {
  DATA_ID: 'territories',
  DATA_LABEL: 'Territories',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ]
}

// TOWNS
export const TOWNS = {
  DATA_ID: 'towns',
  DATA_LABEL: 'Towns',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ]
}

// DISTRIBUTION HOUSES
export const DISTRIBUTION_HOUSES = {
  DATA_ID: 'distribution-houses',
  DATA_LABEL: 'Distribution Houses',
  COLOR: [ 0, 142, 137 ],
  FILTER_KEYS: [
    { key: 'town_name', type: 'string' }
  ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'town_name', format: null }
  ]
}

// ROUTES
export const ROUTES = {
  DATA_ID: 'routes',
  DATA_LABEL: 'Routes',
  STROKE_COLOR: [ 120, 120, 120 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'vehicle', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null },
    { name: 'strike_rate', format: null },
    { name: 'strike_rate_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'name', type: 'string' },
    { key: 'strike_rate', type: 'number' }
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  STRIKE_RATE_COLOR_MAPPINGS: [
    { value: 'High Strike (60% to 100%)', color: '#008000' },
    { value: 'Low Strike (0% to 30%)', color: '#d70040' },
    { value: 'Medium Strike (30% to 60%)', color: '#ffaa33' },
    { value: 'No Strike (0%)', color: '#808080' }
  ],
  FIXED_ROUTES_DATA_ID: 'fixed-routes',
  FIXED_ROUTES_DATA_LABEL: 'Fixed Routes',
  FIXED_ROUTES_STROKE_COLOR: [ 214, 28, 78 ],
  FIXED_ROUTES_TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'code', format: null },
    { name: 'vehicle', format: null }
  ]
}

// SR
export const SR = {
  SR_ROUTE_DATA_ID: 'sr-route',
  SR_ROUTE_DATA_LABEL: 'SO Route',
  SR_ROUTE_WAYPOINTS_DATA_ID: 'sr-route-waypoints',
  SR_ROUTE_WAYPOINTS_DATA_LABEL: 'SO Route Waypoints',
  SR_ROUTE_TOOLTIP_FIELDS: [
    { name: 'distance', format: null },
    { name: 'eta', format: null }
  ],
  SR_WAYPOINTS_TOOLTIP_FIELDS: [],
  SR_ORDERS_DATA_ID: 'sr-orders',
  SR_ORDERS_DATA_LABEL: 'Order Locations',
  SR_ORDERS_COLOR: [ 199, 0, 57 ],
  SR_ORDERS_TOOLTIP_FIELDS: [
    { name: 'order_no', format: null },
    { name: 'order_date', format: null },
    { name: 'outlet_id', format: null },
    { name: 'outlet_code', format: null },
    { name: 'outlet_name', format: null },
    { name: 'brand', format: null },
    { name: 'sku_code', format: null },
    { name: 'sku_name', format: null },
    { name: 'quantity', format: null },
    { name: 'trade_price', format: null },
    { name: 'gross_value', format: null },
    { name: 'order_longitude', format: null },
    { name: 'order_latitude', format: null },
    { name: 'distance_from_outlet_m', format: null },
    { name: 'in_time', format: null },
    { name: 'out_time', format: null },
    { name: 'duration', format: null }
  ],
  SR_VISITED_OUTLETS_DATA_ID: 'sr-visited-outlets',
  SR_VISITED_OUTLETS_DATA_LABEL: 'Outlet Locations',
  SR_VISITED_OUTLETS_COLOR: [ 31, 81, 255 ],
  SR_VISITED_OUTLETS_TOOLTIP_FIELDS: [
    { name: 'business_name', format: null },
    { name: 'address', format: null },
    { name: 'outlet_code', format: null },
    { name: 'route_name', format: null },
    { name: 'in_time', format: null },
    { name: 'out_time', format: null },
    { name: 'duration', format: null },
    { name: 'visit_order', format: null }
  ],
  SR_ORDER_OUTLET_LINESTRING_DATA_ID: 'sr-order-outlet-linestring',
  SR_ORDER_OUTLET_LINESTRING_DATA_LABEL: 'Order-Outlet Connection'
}

// OUTLETS
export const OUTLETS = {
  DATA_ID: 'outlets',
  DATA_LABEL: 'Outlets',
  COLOR: [ 38, 57, 25 ],
  TOOLTIP_FIELDS: [
    { name: 'business_name', format: null },
    { name: 'address', format: null },
    { name: 'outlet_code', format: null },
    { name: 'channel_name', format: null },
    { name: 'clp', format: null },
    { name: 'growth_percentage', format: null },
    { name: 'route_name', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'total_gross_value', format: null },
    { name: 'target_gross_value', format: null },
    { name: 'target_achieved_percentile', format: null },
    { name: 'target_achieved_level', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null }
  ],
  FILTER_KEYS: [
    { key: 'channel_name', type: 'string' },
    { key: 'clp', type: 'string' },
    { key: 'growth_percentage', type: 'number' },
    { key: 'target_achieved_percentile', type: 'number' },
    { key: 'is_late_order', type: 'number' },
    { key: 'is_fast_order', type: 'number' },
    { key: 'is_declining', type: 'number' },
    { key: 'is_compliant', type: 'number' },
    { key: 'is_zero_sales', type: 'number' },
  ],
  SALES_QUANTITY_COLOR_MAPPINGS: [
    { value: 'High Sales (50% or more than ADS)', color: '#008000' },
    { value: 'Low Sales (50% or lesser than ADS)', color: '#d70040' },
    { value: 'Medium Sales (within 50% difference from ADS)', color: '#ffaa33' },
    { value: 'No Sales (0% sales)', color: '#808080' }
  ],
  TARGET_ACHIEVED_COLOR_MAPPINGS: [
    { value: 'High Achieved (60% to 100%)', color: '#008000' },
    { value: 'Low Achieved (0% to 30%)', color: '#d70040' },
    { value: 'Medium Achieved (30% to 60%)', color: '#ffaa33' },
    { value: 'No Achieved (0%)', color: '#808080' }
  ],
  CHANNEL_COLOR_MAPPINGS: [
    { value: 'Regular', color: '#1f77b4' },
    { value: 'Irregular', color: '#ff7f0e' },
    { value: 'Urban Wet Market Grocer', color: '#2ca02c' },
    { value: 'Urban Neighborhood Grocer', color: '#d62728' },
    { value: 'Urban HPC Tong', color: '#9467bd' },
    { value: 'Urban General Store', color: '#8c564b' },
    { value: 'Shoping Complex/Cosmetic Shop', color: '#e377c2' },
    { value: 'Rural Wet Market Grocer', color: '#7f7f7f' },
    { value: 'Rural Neighborhood Grocer', color: '#bcbd22' },
    { value: 'Self Service Store', color: '#17becf' },
    { value: 'Rural Cosmetic Store', color: '#1f77b4' },
    { value: 'Drug Store', color: '#ff7f0e' },
    { value: 'Hotel', color: '#2ca02c' },
    { value: 'Wholesale', color: '#d62728' },
    { value: 'Pollyduth', color: '#9467bd' },
    { value: 'Premium General Store', color: '#8c564b' },
    { value: 'Out of Home', color: '#e377c2' },
    { value: 'Urban Hunter', color: '#7f7f7f' },
    { value: 'Aporajeeta', color: '#bcbd22' },
    { value: 'Consumer Durable outlet', color: '#17becf' },
    { value: 'e-Commerce', color: '#1f77b4' },
    { value: 'Consumer Durable Outlet Wholesale', color: '#ff7f0e' },
    { value: 'RMG Grocer', color: '#2ca02c' },
    { value: 'RMG STORES', color: '#d62728' },
    { value: 'CONSUMER DURABLE MODERN TRADE', color: '#9467bd' }
  ],
  CLP_COLOR_MAPPINGS: [
    { value: 'HIGH', color: '#7aa457' },
    { value: 'MEDIUM', color: '#a46cb7' },
    { value: 'LOW', color: '#cb6a49' },
    { value: 'Other', color: '#49c5cb' }
  ],
  BKOI_OUTLETS_DATA_ID: 'bkoi-outlets',
  FIXED_OUTLETS_DATA_ID: 'fixed-outlets',
  BKOI_OUTLETS_DATA_LABEL: 'Barikoi Outlets',
  FIXED_OUTLETS_DATA_LABEL: 'Fixed Outlets',
  BKOI_OUTLETS_COLOR: [ 136, 67, 242 ],
  FIXED_OUTLETS_COLOR: [ 8, 143, 143 ],
  BKOI_OUTLETS_TOOLTIP_FIELDS: [
    { name: 'business_name', format: null },
    { name: 'address', format: null },
    { name: 'outlet_code', format: null },
    { name: 'channel_name', format: null },
    { name: 'route_name', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null }
  ],
  FIXED_OUTLETS_TOOLTIP_FIELDS: [
    { name: 'business_name', format: null },
    { name: 'address', format: null },
    { name: 'outlet_code', format: null },
    { name: 'channel_name', format: null },
    { name: 'route_name', format: null },
    { name: 'total_gross_quantity', format: null },
    { name: 'total_net_quantity', format: null },
    { name: 'sales_per_day', format: null },
    { name: 'avg_daily_sales', format: null },
    { name: 'sales_quantity_level', format: null }
  ]
}

// Bkoi Clusters
export const BKOI_CLUSTERS = {
  DATA_ID: 'bkoi-clusters',
  DATA_LABEL: 'Bkoi Clusters',
  STROKE_COLOR: [ 214, 28, 78 ],
  TOOLTIP_FIELDS: [
    { name: 'id', format: null },
    { name: 'name', format: null },
    { name: 'outlets_count', format: null },
    { name: 'bkoi_outlets_count', format: null }
  ]
}

// ALL DISTRIBUTION HOUSES
export const ALL_DISTRIBUTION_HOUSES = {
  DATA_ID: 'all-distribution-houses',
  DATA_LABEL: 'All Distribution Houses',
  COLOR: [ 0, 142, 137 ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'town_name', format: null }
  ]
}

// Trace Configurations
export const TRACE = {
  KEY: 'mykey',
  HOST: 'backend.barikoi.com',
  PORT: 6002,
  CLUSTER: 'ap2',
  AUTH_ENDPOINT: 'https://backend.barikoi.com:8888/api/broadcasting/auth',
  AUTH_API: 'https://backend.barikoi.com:8888/api/v1/user/defaults',
  TRACE_API: 'https://backend.barikoi.com:8888/api/v1/users-data',
  ACTIVE_USERS_API: 'https://backend.barikoi.com:8888/api/v1/active-users',
  DATA_ID: 'sr_data',
  DATA_LABEL: 'SO',
  COLOR_PALETTE: [
    { value: 'Inside Route/Online', color: '#228b22' },
    { value: 'Outside Route/Online', color: '#dc143c' }
  ],
  TOOLTIP_FIELDS: [
    { name: 'name', format: null },
    { name: 'designation', format: null },
    { name: 'route_name', format: null },
    { name: 'contact', format: null },
    { name: 'active_status', format: null },
    { name: 'updated_at', format: null },
    { name: 'last_online', format: null },
    { name: 'position', format: null },
    { name: 'active_position', format: null }
  ]
}

// Socket Notifications
export const SOCKET = {
  API_KEY: window?._env_?.REACT_APP_RETAIL_SOCKET_API_KEY ?? '',
  CLUSTER: 'ap1',
  CHANNEL: 'notification',
  SR_POSITION_ALERT_EVENT: 'sr-position-alert',
  SR_ORDER_DELAY_EVENT: 'sr-order-delay-alert',
  SR_POSITION_ALERT_API: `${ API_BASE_URL }/api/sr-position-alert`,
  SR_ORDER_DELAY_API: `${ API_BASE_URL }/api/sr-order-delay-alert`
}