import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'

// Import Components
import { Box, Divider } from '@mui/material'
import StyledSelect from '../common/StyledSelect'
import StatsSelect from './StatsSelect'
import Configs from './Configs'

// Import Actions & Methods
import { setSelectedNation, getRegions, setRegions, setSelectedRegion, getAreas, setAreas, setSelectedArea, getTerritories, setTerritories, setDistributionHouses, setSelectedTerritory, getTowns, setTowns, setSelectedTown, getRoutes, setRoutes, setSelectedRoute, getOutlets, setOutlets, setIsOutletsListExpanded, setSelectedOutletsFilterOption } from '../../redux/actions/navActions'
import { filterNations, filterRegions, clearRegionsFromMap, filterAreas, clearAreasFromMap, filterTerritories, clearTerritoriesFromMap, clearTownsFromMap, filterTowns, filterRoutes, clearRoutesFromMap, clearOutletsFromMap, fitBoundsMap } from '../../redux/actions/mapActions'

class LeftNavBody extends React.PureComponent {

  componentDidUpdate(prevProps) {
    const { dispatch, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedRouteStatsType, selectedTownStatsType, datasets } = this.props
    
    // If selectedNation Change in Props
    if (prevProps?.selectedNation?.value !== selectedNation?.value && selectedNation?.value) {
      this._onSelectedNationUpdate(selectedNation)
    }

    // If selectedRegion Change in Props
    if (prevProps?.selectedRegion?.value !== selectedRegion?.value && selectedRegion?.value) {
      this._onSelectedRegionUpdate(selectedRegion, selectedNation)
    }

    // If selectedArea Change in Props
    if (prevProps?.selectedArea?.value !== selectedArea?.value && selectedArea?.value) {
      this._onSelectedAreaUpdate(selectedArea, selectedRegion)
    }

    // If selectedTerritory Change in Props
    if (prevProps?.selectedTerritory?.value !== selectedTerritory?.value && selectedTerritory?.value) {
      this._onSelectedTerritoryUpdate(selectedTerritory, selectedArea)
    }

    // If selectedTown Change in Props
    if (prevProps?.selectedTown?.value !== selectedTown?.value && selectedTown?.value) {
      this._onSelectedTownUpdate(selectedTown, selectedTerritory)
    }

    // If selectedRoute Change in Props
    if (prevProps?.selectedRoute?.value !== selectedRoute?.value && selectedRoute?.value) {
      this._onSelectedRouteUpdate(selectedRoute, selectedTown)
    }

    // If `selectedRouteStatsType` or `selectedTownStatsType` changes in props
    if (prevProps.selectedRouteStatsType !== selectedRouteStatsType || prevProps.selectedTownStatsType !== selectedTownStatsType) {
      dispatch(setSelectedOutletsFilterOption({ value: 'all-outlets', label: 'All Outlets' }))
    }
  }

  // On Nation Change
  _onNationChange = (e, selectedNation) => {
    const { dispatch } = this.props
    dispatch(setSelectedNation(selectedNation))
  }

  // On Region Change
  _onRegionChange = (e, selectedRegion) => {
    const { dispatch } = this.props
    const coordinates =  selectedRegion?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }
    dispatch(setSelectedRegion(selectedRegion))
  }

  // On Area Change
  _onAreaChange = (e, selectedArea) => {
    const { dispatch } = this.props

    const coordinates =  selectedArea?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }
    dispatch(setSelectedArea(selectedArea))
  }

  // On Territory Change
  _onTerritoryChange = (e, selectedTerritory) => {
    const { dispatch } = this.props

    const coordinates =  selectedTerritory?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }

    dispatch(setSelectedTerritory(selectedTerritory))
  }

  // On Town Change
  _onTownChange = (e, selectedTown) => {
    const { dispatch } = this.props

    const coordinates =  selectedTown?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11))
    }

    dispatch(setSelectedTown(selectedTown))
  }

  // On Route Change
  _onRouteChange = (e, selectedRoute) => {
    const { dispatch } = this.props

    const coordinates =  selectedRoute?.geometry?.coordinates
    if(coordinates){
      dispatch(fitBoundsMap(coordinates, 11.5))
    }

    dispatch(setSelectedRoute(selectedRoute))
  }


  // On selectedNation Update in Props
  _onSelectedNationUpdate = selectedNation => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, currentPage } = this.props

    // Filter Nations Data
    dispatch(
      filterNations({
        name: 'name',
        value: selectedNation?.value === 'All' ?
          [] :
          selectedNation?.value === 'None' ?
            [''] :
            [selectedNation?.name]
      })
    )

    // If `All` or `None` Selected, Clear Region Select to Default
    if (selectedNation.value === 'All' || selectedNation.value === 'None') {
      // Clear All Region
      dispatch(setSelectedRegion({ value: 'None', label: 'None' }))
      dispatch(setRegions([]))
      dispatch(clearRegionsFromMap())

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''
      const regionId = ''
      
      // Load Regions By Nation
      dispatch(getRegions(selectedNation, startDate, endDate, brand, sku, regionId, currentPage === 'Dashboard' ? true : false))

      // Show All Regions
      const selectedRegion = { value: 'All', label: 'All' }
      dispatch(setSelectedRegion(selectedRegion))
      dispatch(
        filterNations({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedRegion Update in Props
  _onSelectedRegionUpdate = (selectedRegion, selectedNation) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, currentPage } = this.props

    // Filter Regions Data
    dispatch(
      filterRegions({
        name: 'name',
        value: selectedRegion?.value === 'All' ?
          [] :
          selectedRegion?.value === 'None' ?
            [''] :
            [selectedRegion?.name]
      })
    )

    // If `All` or `None` Selected, Clear Area Select to Default
    if (selectedRegion.value === 'All' || selectedRegion.value === 'None') {
      // Clear All Areas
      dispatch(setSelectedArea({ value: 'None', label: 'None' }))
      dispatch(setAreas([]))
      dispatch(clearAreasFromMap())

      // If `None` Selected, Show Selected Nation
      if (selectedRegion.value === 'None') {
        // Filter Nations Data
        dispatch(
          filterNations({
            name: 'name',
            value: selectedNation?.value === 'All' ?
              [] :
              selectedNation?.value === 'None' ?
                [''] :
                [selectedNation?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Nation
      if (selectedRegion.value === 'All') {
        // Filter Nations Data
        dispatch(
          filterNations({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''
      const areaId = ''

      // Load Areas By Region
      dispatch(getAreas(selectedRegion, startDate, endDate, brand, sku, areaId, currentPage === 'Dashboard' ? true : false))

      // Show All Areas
      const selectedArea = { value: 'All', label: 'All' }
      dispatch(setSelectedArea(selectedArea))
      dispatch(
        filterRegions({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterNations({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedArea Update in Props
  _onSelectedAreaUpdate = (selectedArea, selectedRegion) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, currentPage } = this.props

    // Filter Areas Data
    dispatch(
      filterAreas({
        name: 'name',
        value: selectedArea?.value === 'All' ?
          [] :
          selectedArea?.value === 'None' ?
            [''] :
            [selectedArea?.name]
      })
    )

    // If `All` or `None` Selected, Clear Territory Select to Default
    if (selectedArea.value === 'All' || selectedArea.value === 'None') {
      // Clear All Territories & Hide Regions
      dispatch(setSelectedTerritory({ value: 'None', label: 'None' }))
      dispatch(setTerritories([]))
      dispatch(clearTerritoriesFromMap())

      // If `None` Selected, Show Selected Region
      if (selectedArea.value === 'None') {
        // Filter Regions Data
        dispatch(
          filterRegions({
            name: 'name',
            value: selectedRegion?.value === 'All' ?
              [] :
              selectedRegion?.value === 'None' ?
                [''] :
                [selectedRegion?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Region
      if (selectedArea.value === 'All') {
        // Filter Regions Data
        dispatch(
          filterRegions({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''
      const territoryId = ''

      // Load Territories By Area
      dispatch(getTerritories(selectedArea, startDate, endDate, brand, sku, territoryId, currentPage === 'Dashboard' ? true : false))

      // Show All Territories && Hide Areas
      const selectedTerritory = { value: 'All', label: 'All' }
      dispatch(setSelectedTerritory(selectedTerritory))
      dispatch(
        filterAreas({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterRegions({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedTerritory Update in Props
  _onSelectedTerritoryUpdate = (selectedTerritory, selectedArea) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, currentPage } = this.props

    // Filter Territory Data
    dispatch(
      filterTerritories({
        name: 'name',
        value: selectedTerritory?.value === 'All' ?
          [] :
          selectedTerritory?.value === 'None' ?
            [''] :
            [selectedTerritory?.name]
      })
    )

    // If `All` or `None` Selected, Clear Town Select to Default
    if (selectedTerritory.value === 'All' || selectedTerritory.value === 'None') {
      // Clear All Towns & Hide Areas
      dispatch(setSelectedTown({ value: 'None', label: 'None' }))
      dispatch(setTowns([]))
      dispatch(setDistributionHouses([]))
      dispatch(clearTownsFromMap())

      // If `None` Selected, Show Selected Area
      if (selectedTerritory.value === 'None') {
        // Filter Area Data
        dispatch(
          filterAreas({
            name: 'name',
            value: selectedArea?.value === 'All' ?
              [] :
              selectedArea?.value === 'None' ?
                [''] :
                [selectedArea?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Area
      if (selectedTerritory.value === 'All') {
        // Filter Area Data
        dispatch(
          filterAreas({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Towns By Territory
      dispatch(getTowns(selectedTerritory, startDate, endDate, brand, sku, currentPage === 'Dashboard' ? true : false))

      // Show All Towns && Hide Territories
      const selectedTown = { value: 'All', label: 'All' }
      dispatch(setSelectedTown(selectedTown))
      dispatch(
        filterTerritories({
          name: 'name',
          value: ['']
        })
      )
      dispatch(
        filterAreas({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedTown Update in Props
  _onSelectedTownUpdate = (selectedTown, selectedTerritory) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, isFixedRoutesOn, currentPage } = this.props

    // Filter Towns Data
    dispatch(
      filterTowns({
        name: 'name',
        value: selectedTown?.value === 'All' ?
          [] :
          selectedTown?.value === 'None' ?
            [''] :
            [selectedTown?.name]
      })
    )

    // If `All` or `None` Selected, Clear Route Select to Default
    if (selectedTown.value === 'All' || selectedTown.value === 'None') {
      // Clear All Routes & Hide Territories
      dispatch(setSelectedRoute({ value: 'None', label: 'None' }))
      dispatch(setRoutes([]))
      dispatch(clearRoutesFromMap())

      // If `None` Selected, Show Selected Territory
      if (selectedTown.value === 'None') {
        // Filter Territories Data
        dispatch(
          filterTerritories({
            name: 'name',
            value: selectedTerritory?.value === 'All' ?
              [] :
              selectedTerritory?.value === 'None' ?
                [''] :
                [selectedTerritory?.name]
          })
        )
      }

      // If `All` Selected, Hide Selected Territory
      if (selectedTown.value === 'All') {
        // Filter Territory Data
        dispatch(
          filterTerritories({
            name: 'name',
            value: ['']
          })
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Routes By Town
      dispatch(getRoutes(selectedTown, startDate, endDate, brand, sku, isFixedRoutesOn, currentPage === 'Dashboard' ? true : false))

      // Show All Routes && Hide Territories
      const selectedRoute = { value: 'All', label: 'All' }
      dispatch(setSelectedRoute(selectedRoute))
      dispatch(
        filterTowns(
          {
            name: 'name',
            value: ['']
          },
          { persistDistributionHouse: true }
        )
      )
      dispatch(
        filterTerritories({
          name: 'name',
          value: ['']
        })
      )
    }
  }

  // On selectedRoute Update in Props
  _onSelectedRouteUpdate = (selectedRoute, selectedTown) => {
    const { dispatch, startDate, endDate, selectedBrand, selectedSku, lateOrderThresholdTime, fastOrderDuration, currentPage } = this.props

    // Filter Route Data
    dispatch(
      filterRoutes({
        name: 'name',
        value: selectedRoute?.value === 'All' ?
          [] :
          selectedRoute?.value === 'None' ?
            [''] :
            [selectedRoute?.name]
      })
    )

    // If `All` or `None` Selected, Clear Outlets Data
    if (selectedRoute.value === 'All' || selectedRoute.value === 'None') {
      // Clear All Outlets & Hide Towns
      dispatch(clearOutletsFromMap())
      dispatch(setOutlets([]))

      // If `None` Selected, Show Selected Towns
      if (selectedRoute.value === 'None') {
        // Filter Towns Data
        dispatch(
          filterTowns({
            name: 'name',
            value: selectedTown?.value === 'All' ?
              [] :
              selectedTown?.value === 'None' ?
                [''] :
                [selectedTown?.name]
          })
        )
      }

      // If `All` Selected, Show Selected Town
      if (selectedRoute.value === 'All') {
        // Filter Towns Data
        dispatch(
          filterTowns(
            {
              name: 'name',
              value: ['']
            },
            { persistDistributionHouse: true }
          )
        )
      }

    } else {
      const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
      const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

      // Load Outlets By Route
      dispatch(
        getOutlets({ route_id: selectedRoute?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, late_hour: lateOrderThresholdTime, fast_order_duration: fastOrderDuration })
      )

      // Hide Towns
      dispatch(
        filterTowns(
          {
            name: 'name',
            value: ['']
          },
          { persistDistributionHouse: true }
        )
      )
    }
  }


  render() {
    const { isNavLoading, nations, selectedNation, regions, selectedRegion, areas, selectedArea, territories, selectedTerritory, towns, selectedTown, routes, selectedRoute, isNationOn, isRegionOn, isAreaOn, isTerritoryOn, isTownOn, isRouteOn, srOrders } = this.props
    
    return (
      <Box sx={containerStyles}>
        <Box sx={bodyContainerStyles}>
          {(isNationOn || (!isNationOn && isRegionOn)) &&
            <StyledSelect
              label='Select Nation'
              name='select-nation'
              value={selectedNation?.value ?? ''}
              onChange={this._onNationChange}
              options={nations}
              disabled={isNavLoading || !isNationOn}
            />
          }

          {(isRegionOn || (!isRegionOn && isAreaOn)) &&
            <StyledSelect
              label='Select Region'
              name='select-region'
              value={selectedRegion?.value ?? ''}
              onChange={this._onRegionChange}
              options={regions}
              disabled={
                isNavLoading || !selectedNation || !selectedNation?.value || selectedNation?.value === 'All' || selectedNation?.value === 'None' || !isRegionOn
              }
            />
          }

          {(isAreaOn || (!isAreaOn && isTerritoryOn)) &&
            <StyledSelect
              label='Select Area'
              name='select-area'
              value={selectedArea?.value ?? ''}
              onChange={this._onAreaChange}
              options={areas}
              disabled={
                isNavLoading || !selectedRegion || !selectedRegion?.value || selectedRegion?.value === 'All' || selectedRegion?.value === 'None' || !isAreaOn
              }
            />
          }

          {(isTerritoryOn || (!isTerritoryOn && isTownOn)) &&
            <StyledSelect
              label='Select Territory'
              name='select-territory'
              value={selectedTerritory?.value ?? ''}
              onChange={this._onTerritoryChange}
              options={territories}
              disabled={
                isNavLoading || !selectedArea || !selectedArea?.value || selectedArea?.value === 'All' || selectedArea?.value === 'None' || !isTerritoryOn
              }
            />
          }

          {(isTownOn || (!isTownOn && isRouteOn)) &&
            <StyledSelect
              label='Select Town'
              name='select-town'
              value={selectedTown?.value ?? ''}
              onChange={this._onTownChange}
              options={towns}
              disabled={
                isNavLoading || !selectedTerritory || !selectedTerritory?.value || selectedTerritory?.value === 'All' || selectedTerritory?.value === 'None' || !isTownOn
              }
            />
          }

          <StyledSelect
            label='Select Route'
            name='select-route'
            value={selectedRoute?.value ?? ''}
            onChange={this._onRouteChange}
            options={routes}
            disabled={
              isNavLoading || !selectedTown || !selectedTown?.value || selectedTown?.value === 'All' || selectedTown?.value === 'None' || !isRouteOn
            }
          />

          <Divider sx={{ my: '0.5rem' }} />


          {/* Load Stats */}
          <StatsSelect />


          { ((srOrders?.length || (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) || (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value))) &&
            <Configs />
          }
        </Box>
      </Box>
    )
  }
}

// Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between'
}

const bodyContainerStyles = theme => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between',
  padding: theme.spacing(1),
  paddingX: theme.spacing(2),
  rowGap: theme.spacing(0.5)
})

// Prop Types
LeftNavBody.propTypes = {
  isNavLoading: PropTypes.bool,
  nations: PropTypes.array,
  selectedNation: PropTypes.object,
  regions: PropTypes.array,
  selectedRegion: PropTypes.object,
  areas: PropTypes.array,
  selectedArea: PropTypes.object,
  territories: PropTypes.array,
  selectedTerritory: PropTypes.object,
  towns: PropTypes.array,
  selectedTown: PropTypes.object,
  routes: PropTypes.array,
  selectedRoute: PropTypes.object,
  outlets: PropTypes.array,
  srOrders: PropTypes.array,
  isOutletsListExpanded: PropTypes.bool,
  datasets: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedBrand: PropTypes.object,
  selectedSku: PropTypes.object,
  isNationOn: PropTypes.bool,
  isRegionOn: PropTypes.bool,
  isAreaOn: PropTypes.bool,
  isTerritoryOn: PropTypes.bool,
  isTownOn: PropTypes.bool,
  isRouteOn: PropTypes.bool,
  selectedOutletsFilterOption: PropTypes.object,
  lateOrderThresholdTime: PropTypes.string,
  selectedRouteStatsType: PropTypes.string,
  selectedTownStatsType: PropTypes.string,
  fastOrderDuration: PropTypes.number,
  isFixedRoutesOn: PropTypes.bool,
  currentPage: PropTypes.string
}

LeftNavBody.defaultProps = {
  isNavLoading: false,
  nations: [],
  selectedNation: null,
  regions: [],
  selectedRegion: null,
  areas: [],
  selectedArea: null,
  territories: [],
  selectedTerritory: null,
  towns: [],
  selectedTown: null,
  routes: [],
  selectedRoute: null,
  outlets: [],
  srOrders: [],
  isOutletsListExpanded: false,
  datasets: {},
  startDate: '',
  endDate: '',
  selectedBrand: null,
  selectedSku: null,
  isNationOn: true,
  isRegionOn: true,
  isAreaOn: true,
  isTerritoryOn: true,
  isTownOn: true,
  isRouteOn: true,
  selectedOutletsFilterOption: null,
  lateOrderThresholdTime: '17:00:00',
  selectedRouteStatsType: 'route',
  selectedTownStatsType: 'town',
  fastOrderDuration: 120,
  currentPage: 'Dashboard',
  isFixedRoutesOn: false,
}

const mapStateToProps = state => ({
  isNavLoading: state?.nav?.isNavLoading ?? false,
  nations: state?.nav?.nations ?? [],
  selectedNation: state?.nav?.selectedNation ?? null,
  regions: state?.nav?.regions ?? [],
  selectedRegion: state?.nav?.selectedRegion ?? null,
  areas: state?.nav?.areas ?? [],
  selectedArea: state?.nav?.selectedArea ?? null,
  territories: state?.nav?.territories ?? [],
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  towns: state?.nav?.towns ?? [],
  selectedTown: state?.nav?.selectedTown ?? null,
  routes: state?.nav?.routes ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  outlets: state?.nav?.outlets ?? [],
  srOrders: state?.stat?.srOrders ?? [],
  isOutletsListExpanded: state?.nav?.isOutletsListExpanded ?? false,
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {},
  startDate: state?.stat?.startDate ?? '',
  endDate: state?.stat?.endDate ?? '',
  selectedBrand: state?.stat?.selectedBrand ?? null,
  selectedSku: state?.stat?.selectedSku ?? null,
  isNationOn: state?.featureControl?.isNationOn ?? true,
  isRegionOn: state?.featureControl?.isRegionOn ?? true,
  isAreaOn: state?.featureControl?.isAreaOn ?? true,
  isTerritoryOn: state?.featureControl?.isTerritoryOn ?? true,
  isTownOn: state?.featureControl?.isTownOn ?? true,
  isRouteOn: state?.featureControl?.isRouteOn ?? true,
  selectedOutletsFilterOption: state?.nav?.selectedOutletsFilterOption ?? null,
  lateOrderThresholdTime: state?.configs?.lateOrderThresholdTime ?? '17:00:00',
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? 'route',
  selectedTownStatsType: state?.stat?.selectedTownStatsType ?? 'town',
  fastOrderDuration: state?.configs?.fastOrderDuration ?? 120,
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false,
  currentPage: state?.nav?.currentPage ?? 'Dashboard'
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavBody)