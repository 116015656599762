import React from 'react'

// Import Components
import { Button } from '@mui/material'

class StyledButton extends React.PureComponent {
  render() {
    const { children, ...buttonProps } = this.props

    return (
      <Button
        variant='contained'
        size='small'
        fullWidth={ true }
        sx={ buttonStyles }
        { ...buttonProps }
      >
        { children }
      </Button>
    )
  }
}

// Styles
const buttonStyles = {
  height: '28px',
  fontSize: '0.7rem',
  whiteSpace: 'nowrap'
}

export default StyledButton