import { MapActionTypes } from '../actions/actionTypes'

const initialState = {
  isMapLoading: false,
  colorByOptions: [],
  selectedColorBy: { value: 'sales_quantity_level', label: 'Sales Quantity Level' },
  selectedStrikeRate: [ 0, 100 ],
  channels: [],
  selectedChannels: [{ value: 'All', label: 'All' }]
}

const mapReducer = (state=initialState, action) => {
  switch(action.type) {
    case MapActionTypes.SET_IS_MAP_LOADING:
      return {
        ...state,
        isMapLoading: action.payload
      }
    
    case MapActionTypes.SET_COLOR_BY_OPTIONS:
      return {
        ...state,
        colorByOptions: action.payload
      }
    
    case MapActionTypes.SET_SELECTED_COLOR_BY:
      return {
        ...state,
        selectedColorBy: action.payload
      }
    
    case MapActionTypes.SET_SELECTED_STRIKE_RATE:
      return {
        ...state,
        selectedStrikeRate: action.payload
      }
    
    case MapActionTypes.SET_CHANNELS:
      return {
        ...state,
        channels: action.payload
      }
    
    case MapActionTypes.SET_SELECTED_CHANNEL:
      return {
        ...state,
        selectedChannels: action.payload
      }
    
    default:
      return state
  }
}

export default mapReducer