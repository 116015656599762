import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Box, Grid, Typography, Divider } from '@mui/material'
import StyledCard from '../common/StyledCard'

class CommonStats extends React.PureComponent {
  // Render Stats
  _renderStats = (stats, helpTexts) => {
    if(!stats || !Object.keys(stats)?.length) {
      return (
        <Grid item={ true } xs={ 12 }>
          <Typography align='center' color='text.secondary'>{ 'No data found.' }</Typography>
        </Grid>
      )
    }

    const statComponents = Object.keys(stats).map((s, i) => {
      const label = s.split('_').map(v => v.replace(v[0], v[0].toUpperCase())).join(' ')
      const value = `${ stats[ s ] }${ s.includes('visit_percentile') || s.includes('strike_rate') ? '%' : s.includes('gross_value') ? ' BDT' : '' }`

      return (
        <Grid key={ i } item={ true } xs={ 6 }>
          <StyledCard
            label={ label }
            value={ value }
            help={ helpTexts.hasOwnProperty(s) ? true : false }
            helpText={ helpTexts[ s ] }
          />
        </Grid>
      )
    })

    return statComponents
  }

  render() {
    const { title, stats, helpTexts } = this.props

    return (
      <Box style={ containerStyles }>
        { title &&
          <React.Fragment>
            <Typography
              variant='caption'
              align='center'
              color='text.secondary'
            >
              { title }
            </Typography>
            <Divider style={{ width: '100%', marginBottom: '1rem' }} />
          </React.Fragment>
        }

        <Grid container={ true } spacing={ 2 }>
          { this._renderStats(stats, helpTexts) }
        </Grid>
      </Box>
    )
  }
}

// JSS Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

// Prop Types
CommonStats.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.object,
  helpTexts: PropTypes.object
}

CommonStats.defaultProps = {
  title: '',
  stats: {},
  helpTexts: {}
}

export default CommonStats