import React from 'react'
import PropTypes from 'prop-types'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js'
import { schemeCategory10 } from 'd3-scale-chromatic'
import zoomPlugin from 'chartjs-plugin-zoom'

// Import Components
import { Bar } from 'react-chartjs-2'

// Register Plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, zoomPlugin)

class BarChart extends React.PureComponent {
  render() {
    const { data, style } = this.props

    return (
      <React.Fragment>
        <div style={{ ...containerStyles, ...style }}>
          <Bar
            data={{
              labels: data?.labels ?? [],
              datasets: data?.datasets?.map((d, i) => ({
                ...d,
                backgroundColor: schemeCategory10[ i % 10 ],
                borderColor: schemeCategory10[ i % 10 ],
                borderWidth: 1
              })) ?? []
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  beginAtZero: true,
                  ticks: {
                    autoSkip: false
                  }
                },
                y: {
                  stacked: false,
                  beginAtZero: true,
                  ticks: {
                    autoSkip: false
                  }
                }
              },
              plugins: {
                legend: {
                  display: true
                },
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                      modifierKey: 'ctrl',
                      speed: 0.01
                    },
                    pinch: {
                      enabled: true
                    },
                    mode: 'x',
                    limits: {
                      x: { min: 'original', max: 2 }
                    }
                  }
                }
              }
            }}
          />
        </div>
      </React.Fragment>
    )
  }
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  width: '100%',
  minHeight: '200px'
}

// Prop Types
BarChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number)
      })
    )
  }),
  style: PropTypes.object
}

BarChart.defaultProps = {
  data: {
    labels: [],
    datasets: []
  },
  style: {}
}

export default BarChart