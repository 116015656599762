import React from 'react';
import { connect } from 'react-redux';
import { Box, Modal } from '@mui/material';
import LeftNavBody from '../LeftNav/LeftNavBody';

// Import Actions & Methods
import { setIsFilterPanelOpen } from '../../redux/actions/navActions';

const FilterPanel = ({isFilterPanelOpen, dispatch}) => {

    // On Handle Close
  const _onHandleClose = () => {
    dispatch(setIsFilterPanelOpen(false))
  }

  return (
    <div>
      {/* Modal */}
      <Modal
        open={isFilterPanelOpen}
        onClose={_onHandleClose}
        aria-labelledby="filter-panel-title"
        aria-describedby="filter-panel-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >

          <LeftNavBody />
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
    isFilterPanelOpen: state?.nav?.isFilterPanelOpen ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel);
