import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'
import { OUTLETS } from '../../App.config';

// Import Components
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import OutletList from '../LeftNav/OutletList';
import StyledToggleButtonGroup from '../common/StyledToggleButtonGroup';

// Import Actions & Methods
import { setFilter } from '@kepler.gl/actions'
import { filterOutlets } from '../../redux/actions/mapActions';
import { setIsOutletsListExpanded, setSelectedOutletsFilterOption } from '../../redux/actions/navActions';

const OutletsPanel = ({ 
    dispatch, 
    selectedOutletsFilterOption, 
    outletsFilterOptions,
    selectedTown,
    selectedRouteStatsType,
    selectedTownStatsType,
    isOutletsListExpanded,
    filters,
    outlets
  }) => {
    // States
    const [tempOutlets, setTempOutlets] = useState([])

    // Set Selected Outlets Filter Option
    const _selectedOutletsFilterOptionChange = (selectedOutletsFilterOptions) => {
      dispatch(setSelectedOutletsFilterOption(selectedOutletsFilterOptions))
    }

      // On Outlets List Toggle
    const  _onOutletsListToggle = (e, isOutletsListExpanded) => {
        dispatch(setIsOutletsListExpanded(isOutletsListExpanded))
    }
  

    useEffect(() => {
      // Available Filters
      const availableFilters = ['is_late_order', 'is_fast_order', 'is_declining', 'is_compliant', 'is_zero_sales']

      // Clear Outlet Filters on Left Nav
      filters.forEach((f, i) => {
        if (f.dataId.includes(OUTLETS.DATA_ID) && availableFilters.includes(f.name.length ? f.name[0] : '')) {
          dispatch(setFilter(i, 'value', [0, 1]))
        }
      })

      if(selectedOutletsFilterOption?.value === 'all-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets)

        return
      }
  
      if (selectedOutletsFilterOption?.value === 'late-order-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets.filter(o => o.is_late_order === 1))

        // Filter Outlets by Late Order Outlets
        dispatch(
          filterOutlets({
            name: 'is_late_order',
            value: [1, 1]
          })
        )
  
        return
      }
  
      if (selectedOutletsFilterOption?.value === 'fast-order-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets.filter(o => o.is_fast_order === 1))

        // Filter Outlets by Fast Order Outlets
        dispatch(
          filterOutlets({
            name: 'is_fast_order',
            value: [1, 1]
          })
        )
  
        return
      }
  
      if (selectedOutletsFilterOption?.value === 'declining-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets.filter(o => o.is_declining === 1))

        // Filter Outlets by Declining
        dispatch(
          filterOutlets({
            name: 'is_declining',
            value: [1, 1]
          })
        )
  
        return
      }
  
      if (selectedOutletsFilterOption?.value === 'non-compliant-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets.filter(o => o.is_compliant === 1))

        // Filter Outlets by Non Compliant
        dispatch(
          filterOutlets({
            name: 'is_compliant',
            value: [1, 1]
          })
        )
  
        return
      }
  
      if (selectedOutletsFilterOption?.value === 'zero-sales-outlets') {
        // Set Temp Outlets List
        setTempOutlets(() => outlets.filter(o => o.is_zero_sales === 1))

        // Filter Outlets by Zero Sales
        dispatch(
          filterOutlets({
            name: 'is_zero_sales',
            value: [1, 1]
          })
        )
  
        return
      }
    }, [selectedOutletsFilterOption])

    return (
      (selectedTown?.value && selectedTown?.value !== 'None' && selectedTown?.value !== 'All') ? 
        <>
        <Divider sx={{ my: '0.5rem' }} />
        <StyledToggleButtonGroup
          value={selectedOutletsFilterOption?.value ?? ''}
          onChange={_selectedOutletsFilterOptionChange}
          buttonOptions={outletsFilterOptions}
          disabled={selectedTown?.value === 'None' || selectedTown?.value === 'All' || selectedRouteStatsType !== 'route' || selectedTownStatsType !== 'town'}
        />
        <Accordion
          variant='outlined'
          elevation={0}
          disableGutters={true}
          expanded={isOutletsListExpanded}
          onChange={_onOutletsListToggle}
          square={true}
          sx={{
            '&::before': {
              height: 0
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ChevronDown size={20} />}
            sx={{
              color: 'text.secondary',
              '&.MuiButtonBase-root': {
                minHeight: '24px'
              },
              '& .MuiAccordionSummary-content': {
                my: '4px'
              }
            }}
          >
            <Typography
              color='text.secondary'
              fontSize='0.8rem'
            >
              {/* {`${isSrOrdersLayerOn && srVisitedOutlets?.length ? 'Order Outlets' : selectedOutletsFilterOption?.label ?? ''} (${isSrOrdersLayerOn && srVisitedOutlets?.length ? srVisitedOutlets?.length ?? 0 : datasets[OUTLETS.DATA_ID]?.filteredIndexForDomain?.length ?? 0})`} */}
              {`${ selectedOutletsFilterOption?.label ?? ''} (${tempOutlets?.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <OutletList
              outlets={tempOutlets}
            />
          </AccordionDetails>
        </Accordion>
      </> : (
        <Grid item={ true } xs={ 12 } style={{ marginTop: '1rem' }}>
          <Typography align='center' color='text.secondary'>{ 'No data found.' }</Typography>
        </Grid>
      )
    )
}

const mapStateToProps = state => ({
    selectedOutletsFilterOption: state?.nav?.selectedOutletsFilterOption ?? null,
    outletsFilterOptions: state?.nav?.outletsFilterOptions ?? [],
    selectedTown: state?.nav?.selectedTown ?? null,
    selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? 'route',
    selectedTownStatsType: state?.stat?.selectedTownStatsType ?? 'town',
    isOutletsListExpanded: state?.nav?.isOutletsListExpanded ?? false,
    filters: state?.keplerGl?.map?.visState?.filters ?? [],
    outlets: state?.nav?.outlets ?? [],
})

const mapDispatchToProps = dispatch => ({ dispatch })
  
export default connect(mapStateToProps, mapDispatchToProps)(OutletsPanel)