import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { FormControlLabel, Switch } from '@mui/material'

class StyledSwitch extends React.PureComponent {
  render() {
    const { label, checked, value, onChange } = this.props

    return (
      <FormControlLabel
        control={
          <Switch size='small' />
        }
        label={ label }
        checked={ checked }
        value={ value }
        onChange={ onChange }
        sx={{
          m: 0,
          p: 0,
          '& .MuiFormControlLabel-label': {
            fontSize: '0.8rem'
          }
        }}
      />
    )
  }
}

// Prop Types
StyledSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func
}

StyledSwitch.defaultProps = {
  label: '',
  checked: false,
  value: false,
  onChange: () => null
}

export default StyledSwitch