import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

class StyledSelect extends React.PureComponent {
  // On Change
  _onChange = e => {
    const { options, onChange } = this.props

    if(e.target.value === 'None') {
      return onChange(e, { value: 'None', label: 'None' })
    }

    if(e.target.value === 'All') {
      return onChange(e, { value: 'All', label: 'All' })
    }

    // Find Selected Option
    const selectedOption = options?.find(o => o.value === e.target.value) ?? { value: 'All', label: 'All' }

    return onChange(e, selectedOption)
  }

  render() {
    const { label, value, name, options, disabled, noneOption, allOption } = this.props

    return (
      <FormControl margin='dense' size='small' fullWidth={ true } sx={{ my: '4px' }}>
        <InputLabel
          margin='dense'
          variant='outlined'
          sx={{
            '&.MuiInputLabel-root': {
              mt: '-2px',
              fontSize: '0.8rem',
            },
            '&.MuiInputLabel-shrink': {
              mt: '4px',
              ml: '-4px',
              px: '8px',
              fontSize: '0.8rem',
              background: '#fff'
            }
          }}
        >
          { label }
        </InputLabel>
        <Select
          displayEmpty={ true }
          size='small'
          name={ name }
          value={ value }
          label={ label }
          onChange={ this._onChange }
          disabled={ disabled }
          notched={ false }
          sx={{
            mt: '2px',
            maxHeight: '28px',
            fontSize: '0.7rem',
            '& .MuiSelect-icon': {
              fontSize: '1rem'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important'
            }
          }}
        >
          { noneOption &&
            <MenuItem dense={ true } value='None' sx={{ fontSize: '0.7rem' }}><i>{ 'None' }</i></MenuItem>
          }
          { allOption &&
            <MenuItem dense={ true } value='All' sx={{ fontSize: '0.7rem' }}><i>{ 'All' }</i></MenuItem>
          }

          {
            options?.map((o, i) =>
              <MenuItem
                key={ i }
                dense={ true }
                value={ o?.value ?? '' }
                sx={{
                  fontSize: '0.7rem'
                }}
              >
                { o?.label ?? '' }
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    )
  }
}

// Prop Types
StyledSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  noneOption: PropTypes.bool,
  allOption: PropTypes.bool
}

StyledSelect.defaultProps = {
  label: '',
  value: '',
  name: '',
  onChange: () => null,
  options: [],
  disabled: false,
  noneOption: true,
  allOption: true
}

export default StyledSelect