import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _debounce from 'lodash.debounce'

// Import Components
import { Box } from '@mui/material'
import ErrorBoundary from '../common/ErrorBoundary'
import KeplerGL from './KeplerGL'
import ZoomControl from './ZoomControl'
import FixedTooltip from './FixedTooltip'

// Import Actions & Methods
import { interactionConfigChange } from '@kepler.gl/actions'
import { loadInitialCustomMap, setLayerSizeWithZoomLevel, setLayerOrder } from '../../redux/actions/mapActions'

class MapGL extends React.PureComponent {
  state = {
    mapWidth: 240,
    mapHeight: 240
  }
  
  componentDidMount() {
    const { dispatch } = this.props

    // Handle Map Resize
    this._handleMapResize()

    // Load Initial Map Configs
    dispatch( loadInitialCustomMap() )

    // Disable Tooltip
    dispatch( interactionConfigChange({ tooltip: { enabled: false } }) )
  }

  componentDidUpdate(prevProps) {
    const { dispatch, zoom, layers } = this.props

    // If `zoom` changes in props
    if(prevProps.zoom !== zoom) {
      dispatch( setLayerSizeWithZoomLevel(zoom) )
    }

    // If `layers` added or removed in props
    if(layers?.length && prevProps.layers?.length !== layers?.length) {
      // Reorder Layers to Put Trace layer on Top
      dispatch( setLayerOrder() )
    }
  }

  // Handle Map Resize
  _handleMapResize = () => {
    // Add Event Listeners
    const mapContainerElement = document.getElementById('map-container')
    if(mapContainerElement) {
      new ResizeObserver(_debounce(() => {
        this.setState({
          mapWidth: mapContainerElement.clientWidth,
          mapHeight: mapContainerElement.clientHeight
        })
      }, 10)).observe(mapContainerElement)
    }
  }

  render() {
    const { mapWidth, mapHeight } = this.state

    return (
      <Box id='map-container' sx={ containerStyles }>
        <ErrorBoundary>
          <KeplerGL
            id='map'
            mapboxApiAccessToken={ '' }
            width={ mapWidth }
            height={ mapHeight }
            mint={ true }
          />
          <ZoomControl />
          <FixedTooltip />
        </ErrorBoundary>
      </Box>
    )
  }
}

// Styles
const containerStyles = {
  width: '100%',
  height: '100%',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  position: 'relative'
}

// Prop Types
MapGL.propTypes = {
  zoom: PropTypes.number,
  layers: PropTypes.array,
  dispatch: PropTypes.func
}

MapGL.defaultProps = {
  zoom: 10,
  layers: [],
  dispatch: () => null
}

const mapStateToProps = state => ({
  zoom: state?.keplerGl?.map?.mapState?.zoom ?? 10,
  layers: state?.keplerGl?.map?.visState?.layers ?? []
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(MapGL)