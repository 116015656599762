import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEqual from 'fast-deep-equal'
import { SR } from '../../App.config'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsOrdersLayerOn } from '../../redux/actions/statActions'
import { setSrOrdersLayerVisibility, loadSrOrdersToMap, clearSrOrdersFromMap, setOutletsLayerVisibility, loadOrderOutletLineString, clearOrderOutletLineString } from '../../redux/actions/mapActions'

class OrdersLayerControl extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { dispatch, isSrOrdersLayerOn, srOrders, srVisitedOutlets, clicked } = this.props

    // If `srOrders` or `srVisitedOutlets` changes in props
    if(prevProps.isSrOrdersLayerOn !== isSrOrdersLayerOn || !_isEqual(prevProps.srOrders, srOrders) || !_isEqual(prevProps.srVisitedOutlets, srVisitedOutlets)) {
      if(isSrOrdersLayerOn) {
        // Load SR Orders To Map
        dispatch( loadSrOrdersToMap(srOrders, srVisitedOutlets) )

      } else {
        // Clear SR Orders From Map
        dispatch( clearSrOrdersFromMap() )
      }
    }

    // If `isSrOrdersLayerOn` changes in props
    if(prevProps.isSrOrdersLayerOn !== isSrOrdersLayerOn) {
      dispatch( setSrOrdersLayerVisibility(isSrOrdersLayerOn) )
      dispatch( setOutletsLayerVisibility(!isSrOrdersLayerOn) )
    }

    // If `clicked` changes in props
    if(prevProps?.clicked !== clicked || prevProps?.clicked?.layer?.id !== clicked?.layer?.id || prevProps?.clicked?.index !== clicked?.index) {
      // If Orders Layer Clicked
      if(this._getDataIdFromLayerId(clicked?.layer?.id ?? '') === SR.SR_ORDERS_DATA_ID) {
        // Add Temporary Line Towards Outlet From Order Point
        const featureCollection = this._getSrOrderOutletLineString(clicked)
        if(featureCollection) {
          dispatch( loadOrderOutletLineString(featureCollection) )
        }

      } else if(this._getDataIdFromLayerId(clicked?.layer?.id ?? '') === SR.SR_VISITED_OUTLETS_DATA_ID) {
        // Add Temporary Line Towards Orcer From Outlet Point
        const featureCollection = this._getSrOutletOrderLineString(clicked)
        if(featureCollection) {
          dispatch( loadOrderOutletLineString(featureCollection) )
        }

      } else {
        dispatch( clearOrderOutletLineString() )
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch( setIsOrdersLayerOn(false) )
    dispatch( clearSrOrdersFromMap() )
    dispatch( setOutletsLayerVisibility(true) )
    dispatch( clearOrderOutletLineString() )
  }

  // On Orders Layer Control Change
  _onOrdersLayerControlChange = (e, value) => {
    const { dispatch } = this.props
    dispatch( setIsOrdersLayerOn(value) )
  }

  // Get DATA ID From Layer ID
  _getDataIdFromLayerId = layerId => {
    const { layers } = this.props
    const layer = layers.find(l => l.id === layerId)

    if(!layer) {
      return ''
    }

    return layer?.config?.dataId ?? ''
  }

  // Get SR Order-Outlet LineString GeoJSON
  _getSrOrderOutletLineString = clicked => {
    const { srVisitedOutlets, srOrdersDataset } = this.props

    if(!clicked?.object?.data?.length || !srVisitedOutlets?.length) {
      return null
    }

    const outletIdField = srOrdersDataset.fields.find(f => f.name === 'outlet_id')
    const orderLongitudeField = srOrdersDataset.fields.find(f => f.name === 'order_longitude')
    const orderLatitudeField = srOrdersDataset.fields.find(f => f.name === 'order_latitude')

    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    }

    if(outletIdField) {
      featureCollection.features = srVisitedOutlets.filter(srv =>
        srv.id === clicked.object.data[ outletIdField.tableFieldIndex-1 ]
      )
        .map(sv => ({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              [
                clicked.object.data[ orderLongitudeField.tableFieldIndex-1 ],
                clicked.object.data[ orderLatitudeField.tableFieldIndex-1 ]
              ],
              [
                sv.longitude,
                sv.latitude
              ]
            ]
          }
        }))
    }

    return featureCollection
  }

  // Get SR Outlet-Order LineString GeoJSON
  _getSrOutletOrderLineString = clicked => {
    const { srOrders, srVisitedOutletsDataset } = this.props

    if(!clicked?.object?.data?.length || !srOrders?.length) {
      return null
    }

    const outletIdField = srVisitedOutletsDataset.fields.find(f => f.name === 'id')
    const outletLongitudeField = srVisitedOutletsDataset.fields.find(f => f.name === 'longitude')
    const outletLatitudeField = srVisitedOutletsDataset.fields.find(f => f.name === 'latitude')

    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    }

    if(outletIdField) {
      featureCollection.features = srOrders.filter(sro =>
        sro.outlet_id === clicked.object.data[ outletIdField.tableFieldIndex-1 ]
      )
        .map(so => ({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              [
                clicked.object.data[ outletLongitudeField.tableFieldIndex-1 ],
                clicked.object.data[ outletLatitudeField.tableFieldIndex-1 ]
              ],
              [
                so.order_longitude,
                so.order_latitude
              ]
            ]
          }
        }))
    }

    return featureCollection
  }
  
  render() {
    const { isSrOrdersLayerOn } = this.props

    return (
      <Box>
        <StyledSwitch
          label='SO Orders'
          checked={ isSrOrdersLayerOn }
          value={ isSrOrdersLayerOn }
          onChange={ this._onOrdersLayerControlChange }
        />
      </Box>
    )
  }
}

// Prop Types
OrdersLayerControl.propTypes = {
  isSrOrdersLayerOn: PropTypes.bool,
  srOrders: PropTypes.array,
  srVisitedOutlets: PropTypes.array,
  clicked: PropTypes.object,
  layers: PropTypes.array,
  srOrdersDataset: PropTypes.object,
  srVisitedOutletsDataset: PropTypes.object,
  dispatch: PropTypes.func
}

OrdersLayerControl.defaultProps = {
  isSrOrdersLayerOn: false,
  srOrders: [],
  srVisitedOutlets: [],
  clicked: null,
  layers: [],
  srOrdersDataset: null,
  srVisitedOutletsDataset: null,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isSrOrdersLayerOn: state?.stat?.isSrOrdersLayerOn ?? false,
  srOrders: state?.stat?.srOrders ?? [],
  srVisitedOutlets: state?.stat?.srVisitedOutlets ?? [],
  clicked: state?.keplerGl?.map?.visState?.clicked ?? null,
  layers: state?.keplerGl?.map?.visState?.layers ?? [],
  srOrdersDataset: state?.keplerGl?.map?.visState?.datasets[ SR.SR_ORDERS_DATA_ID ] ?? null,
  srVisitedOutletsDataset: state?.keplerGl?.map?.visState?.datasets[ SR.SR_VISITED_OUTLETS_DATA_ID ] ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(OrdersLayerControl)