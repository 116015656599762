import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'

class StyledRadioGroup extends React.PureComponent {
  render() {
    const { options, value, disabled, onChange } = this.props

    return (
      <FormControl fullWidth={ true } margin='none' size='small' disabled={ disabled }>
        <RadioGroup
          row={ true }
          value={ value }
          onChange={ onChange }
        >
          { options.map((o, i) =>
              <FormControlLabel
                key={ i }
                value={ o.value }
                label={ o.label }
                control={ <Radio fontSize='small' /> }
                sx={ formControlLabelStyles }
              />
            )
          }
        </RadioGroup>
      </FormControl>
    )
  }
}

// Styles
const formControlLabelStyles = {
  '& .MuiFormControlLabel-label': {
    fontSize: '0.7rem'
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1rem'
  }
}

// Prop Types
StyledRadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]), label: PropTypes.string })),
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

StyledRadioGroup.defaultProps = {
  options: [],
  value: '',
  disabled: false,
  onChange: () => null
}

export default StyledRadioGroup