import axios from 'axios'
import { utils as xlsxUtils, writeFile as xlsxWriteFile } from 'xlsx'
import { API } from '../App.config'

// Union Array Of Objects By Key
export function unionArrayOfObjects(array1, array2, key) {
  const array = [ ...array1 ]
  array2.forEach(o => {
    const index = array.findIndex(a => a[ key ] === o[ key ])
    if(index >= 0) {
      array[ index ] = o

    } else {
      array.push(o)
    }
  })
  
  return array
}

// Format Duration. Param in seconds
export function formatDuration(value) {
  if(!value) {
    return '0s'
  }

  const hours = Math.floor(value / 3600)
  const minutes = Math.floor((value - (hours * 3600)) / 60)
  const seconds = value - (hours * 3600) - (minutes * 60)

  return `${ hours ? (hours + 'h ') : '' }${ minutes ? (minutes + 'm ') : '' }${ seconds ? (seconds + 's') : '' }`.trim()
}

// Format Distance. Param in meters
export function formatDistance(value) {
  if(!value) {
    return '0m'
  }

  if(value < 1000) {
    return `${ value }m`
  }

  const kilometers = (value / 1000).toFixed(2)

  return `${ kilometers }km`
}

// Get Common Keys Among a Collection Of Objects
export function getCommonKeys(objects) {
  // If objects invalid value
  if(!objects?.length) {
    return []
  }

  const keys = objects.reduce((acc, o) => {
    return acc.concat(Object.keys(o))
  }, [])

  return Array.from(new Set(keys))
}

// Sort Options By Label in Ascending Order
export function sortOptionsAsc(options) {
  const sortedOptions = options?.sort((o1, o2) => {
    const labelO1 = o1?.label?.trim()?.toLowerCase() ?? ''
    const labelO2 = o2?.label?.trim()?.toLowerCase() ?? ''

    if(labelO1 < labelO2) {
      return -1
    }

    if(labelO1 > labelO2) {
      return 1
    }

    return 0
  })
  ??
  []

  return sortedOptions
}


// Export Array of Objects in a CSV Format
export function exportObjectsAsCsv(objects, fileName) {
  if(!objects?.length) {
    return
  }

  const worksheet = xlsxUtils.json_to_sheet(objects)
  const workbook = xlsxUtils.book_new()
  xlsxUtils.book_append_sheet(workbook, worksheet, fileName)
  xlsxWriteFile(workbook, `${ fileName }.csv`, { bookType: 'csv' })
}


// Export Array of Objects in a XLSX Format
export function exportObjectsAsXlsx(objects, fileName) {
  if(!objects?.length) {
    return
  }

  const worksheet = xlsxUtils.json_to_sheet(objects)
  const workbook = xlsxUtils.book_new()
  xlsxUtils.book_append_sheet(workbook, worksheet, fileName)
  xlsxWriteFile(workbook, `${ fileName }.xlsx`, { bookType: 'xlsx' })
}

// Get Max Value From Array
export function getMaxValue(array, key='') {
  let maxValue = Number.MIN_SAFE_INTEGER

  array?.forEach(so => {
    if(so.hasOwnProperty(key)) {
      if(so[ key ] > maxValue) {
        maxValue = so[ key ]
      }
    }
  })
  
  return maxValue
}

// Get Min Max Value From Array
export function getMinMaxValue(array, key='', padEnd=false) {
  let minValue = Number.MAX_SAFE_INTEGER
  let maxValue = Number.MIN_SAFE_INTEGER

  array?.forEach(so => {
    if(so.hasOwnProperty(key)) {
      if(so[ key ] < minValue) {
        minValue = so[ key ]
      }

      if(so[ key ] > maxValue) {
        maxValue = so[ key ]
      }
    }
  })

  if(padEnd) {
    return [ Math.floor(minValue), Math.ceil(maxValue) ]
  }
  
  return [ minValue, maxValue ]
}

// Reverse Geo
export function reverseGeo(options={ longitude: '', latitude: '' }) {
  const { longitude, latitude } = options
  return axios.get(API.REVERSE_GEO_WITHOUT_AUTH, { params: { longitude, latitude } })
    .then(res => {
      if(res?.data?.length) {
        return res.data[0]
      }

      return {}
    })
    .catch(err => {
      throw err
    })
}