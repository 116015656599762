import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box, Typography, Slider } from '@mui/material'

// Import Actions & Methods
import { filterOutlets } from '../../redux/actions/mapActions'

const FilterByGrowthPercentage = ({ dispatch, selectedTown, datasets, minMaxGrowthPercentage, growthPercentageRangeMarks, selectedGrowthPercentage, onGrowthPercentageChange }) => {
  // On Growth Percentage Change Committed
  const _onGrowthPercentageChangeCommitted = (e, selectedGrowthPercentage) => {
    // If Single Route Selected
    if(selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value && datasets[ OUTLETS.DATA_ID ]) {
      // Filter Outlets by Growth Percentage
      dispatch(
        filterOutlets({
          name: 'growth_percentage',
          value: selectedGrowthPercentage
        })
      )
    }
  }

  return (
    <Box paddingX='0.8rem'>
      <Typography
        align='left'
        noWrap={ true }
        variant='caption'
        color='textSecondary'
      >
        { 'by Growth Percentage' }
      </Typography>
      <Slider
        name='growth-percentage-range'
        size='small'
        marks={ growthPercentageRangeMarks }
        max={ Math.ceil(minMaxGrowthPercentage[ 1 ]) }
        min={ Math.floor(minMaxGrowthPercentage[ 0 ]) }
        step={ 1 }
        value={ selectedGrowthPercentage }
        valueLabelDisplay='auto'
        onChange={ onGrowthPercentageChange }
        onChangeCommitted={ _onGrowthPercentageChangeCommitted }
        sx={{
          margin: 0,
          marginTop: '-0.5rem',
          '& .MuiSlider-markLabel': {
            marginTop: '-0.8rem'
          }
        }}
      />
    </Box>
  )
}

// Prop Types
FilterByGrowthPercentage.propTypes = {
  selectedTown: PropTypes.object,
  datasets: PropTypes.object,
  minMaxGrowthPercentage: PropTypes.array,
  growthPercentageRangeMarks: PropTypes.array,
  selectedGrowthPercentage: PropTypes.array,
  onGrowthPercentageChange: PropTypes.func,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {}
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterByGrowthPercentage)