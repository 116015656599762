// Import Components
import { Box } from '@mui/material'
import UserList from './UserList'

const TraceSo = () => {
  return (
    <Box sx={ containerStyles }>
      <Box sx={{ ...bodyContainerStyles, maxHeight: `${ document.querySelector('.right-panel-body')?.clientHeight - 32 }px` }}>
        <Box padding='0.8rem' width='100%'>
          <UserList />
        </Box>
      </Box>
    </Box>
  )
}

// JSS Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between'
}

const bodyContainerStyles = {
  padding: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between',
  overflow: 'auto'
}

export default TraceSo