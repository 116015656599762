import Pusher from 'pusher-js'
import axios from 'axios'
import { SOCKET } from '../../App.config'
import { SocketActionTypes } from '../actions/actionTypes'

// Set SR Position Data
export function setSrPositionData(srPositionData) {
  return dispatch => {
    dispatch({ type: SocketActionTypes.SET_SR_POSITION_DATA, payload: srPositionData })
  }
}

// Set SR Order Delay Data
export function setSrOrderDelayData(srOrderDelayData) {
  return dispatch => {
    dispatch({ type: SocketActionTypes.SET_SR_ORDER_DELAY_DATA, payload: srOrderDelayData })
  }
}

// Activate Socket Notifications
export function activateSocketNotifications() {
  return dispatch => {
    // Pusher Configs
    window.pusher = new Pusher(SOCKET.API_KEY, {
      cluster: SOCKET.CLUSTER
    })

    // Bind Channel
    const channel = window.pusher.subscribe(SOCKET.CHANNEL)

    // Bind Events
    channel.bind(SOCKET.SR_POSITION_ALERT_EVENT, data =>{
      if(data?.message && data?.position) {
        dispatch( setSrPositionData(data) )
      }
    })

    channel.bind(SOCKET.SR_ORDER_DELAY_EVENT, data => {
      if(data?.message) {
        dispatch( setSrOrderDelayData(data) )
      }
    })
  }
}

// Deactivate Socket Notifications
export function deactivateSocketNotifications() {
  return () => {
    if(window.pusher) {
      window.pusher.unsubscribe(SOCKET.CHANNEL)
      delete window.pusher
    }
  }
}

// Trigger SR Position Out Of Bounds Event
export function triggerSrPositionAlertEvent(data) {
  return () => {
    axios.get(SOCKET.SR_POSITION_ALERT_API, { params: { message: data?.message ?? '', position: data?.position ?? '' } })
      .catch(err => {
        console.error(err)
      })
  }
}

// Trigger SR Order Delay Event
export function triggerSrOrderDelayAlertEvent(data) {
  return () => {
    axios.get(SOCKET.SR_ORDER_DELAY_API, { params: { message: data?.message ?? '' } })
      .catch(err => {
        console.error(err)
      })
  }
}