import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, IconButton } from '@mui/material'
import { Plus, Minus, Navigation2 } from 'lucide-react'

// Import Actions & Methods
import { updateMap } from '@kepler.gl/actions'

const ZoomControl = ({ dispatch, zoom, bearing }) => {
  // On Zoom In
  const _onZoomIn = () => {
    if(zoom + 1 <= 24) {
      dispatch( updateMap({ zoom: zoom + 1 }) )
    }
  }

  // On Zoom Out
  const _onZoomOut = () => {
    if(zoom - 1 >= 0) {
      dispatch( updateMap({ zoom: zoom - 1 }) )
    }
  }

  return (
    <Box style={ containerStyles }>
      <IconButton
        size='small'
        onClick={ _onZoomIn }
        sx={{ color: '#6a7485' }}
      >
        <Plus size={20} />
      </IconButton>
      <IconButton
        size='small'
        onClick={ _onZoomOut }
        sx={{ color: '#6a7485' }}
      >
        <Minus size={20} />
      </IconButton>
      <IconButton
        size='small'
        disableRipple={ true }
        disableTouchRipple={ true }
        focusRipple={ false }
        sx={{ color: '#6a7485' }}
      >
        <Navigation2 size={20} />
      </IconButton>
    </Box>
  )
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  position: 'absolute',
  top: '1rem',
  left: '0.75rem',
  background: '#29323c',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

// Prop Types
ZoomControl.propTypes = {
  zoom: PropTypes.number,
  bearing: PropTypes.number,
  dispatch: PropTypes.func
}


const mapStateToProps = state => ({
  zoom: state?.keplerGl?.map?.mapState?.zoom ?? 10,
  bearing: state?.keplerGl?.map?.mapState?.bearing ?? 0
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ZoomControl)