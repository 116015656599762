import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { AppBar, Tabs, Tab, Tooltip } from '@mui/material'

class StyledTabsMenu extends React.PureComponent {
  render() {
    const { width, value, tabOptions, iconSize, onChange, ...appBarProps } = this.props

    return (
      <AppBar position='relative' sx={{ ...appBarStyles, width }} { ...appBarProps }>
        <Tabs
          variant='fullWidth'
          scrollButtons='auto'
          value= { value }
          onChange = { onChange }
          sx={ tabsStyles }
        >
          { tabOptions.map((t, i) =>
              t.label ?
                <Tab
                  key={ i }
                  label={ t.label ? t.label : '' }
                  icon={ t.icon ? <t.icon size={20} style={{ marginBottom: 1 }} /> : null }
                  iconPosition='start'
                  sx={ tabStyles }
                />
                :
                <Tooltip key={ i } title={ t.label } arrow={ true } disableFocusListener={ true }>
                  <Tab
                    label={ t.label ? t.label : '' }
                    icon={ t.icon ? <t.icon size={20} style={{ marginBottom: 1 }} /> : null }
                    iconPosition='start'
                    sx={ tabStyles }
                  />
                </Tooltip>
            )
          }
        </Tabs>
      </AppBar>
    )
  }
}

// Styles
const appBarStyles = {
  '&.MuiAppBar-root': {
    flexGrow: 1,
    maxWidth: '100%'
  },
  '&.MuiAppBar-colorPrimary': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}

const tabsStyles = {
  '&.MuiTabs-root': {
    minHeight: '32px',
    height: '32px',
    color: 'white'
  }
}

const tabStyles = {
  '&.MuiTab-root': {
    minHeight: '32px !important',
    height: '32px !important',
    minWidth: '20px !important',
    width: '48px !important',
    fontSize: '0.7rem'
  },
  '& .MuiTab-labelIcon': {
    fontSize: '0.4rem'
  }
}

// Prop Types
StyledTabsMenu.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  tabOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, icon: PropTypes.any })),
  iconSize: PropTypes.string,
  onChange: PropTypes.func.isRequired
}
  
StyledTabsMenu.defaultProps = {
  value: 0,
  width: '100%',
  tabOptions: [],
  iconSize: '1rem',
  onChange: () => null
}

export default StyledTabsMenu