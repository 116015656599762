import bkoiLogoBlack from '../assets/bkoi_logo_black.png'

// Render Barikoi Attributions
export function renderBarikoiAttributions() {
  const attrIntervalId = setInterval(() => {
    // Locate the attribution container
    const attributionContainer = document.querySelector('.maplibre-attribution-container');
    const attributionLink = attributionContainer?.querySelector('.attrition-link');
    const attributionLogo = attributionLink?.querySelector('.maplibregl-ctrl-logo');

    // console.log(attributionContainer, 'attributionContainer');
    // console.log(attributionLink, 'attributionLink');
    // console.log(attributionLogo, 'attributionLogo');

    // Check if required elements are found
    if (attributionContainer && attributionLink && attributionLogo) {
      // Adjust container margin
      attributionContainer.style.margin = '3px'

      // Create a new Barikoi logo element
      const newAttributionLogo = document.createElement('a');
      newAttributionLogo.setAttribute('class', 'barikoigl-ctrl-logo');
      newAttributionLogo.setAttribute('target', '_blank');
      newAttributionLogo.setAttribute('rel', 'noopener noreferrer');
      newAttributionLogo.setAttribute('aria-label', 'Barikoi Logo');
      newAttributionLogo.style.marginLeft = '5px';

      // Add Barikoi Logo SVG
      const svg = document.createElement('img');
      svg.setAttribute('src', bkoiLogoBlack);
      svg.setAttribute('width', '40px');
      svg.style.marginTop = '-2px';
      newAttributionLogo.appendChild(svg);

      // Replace the existing logo
      attributionLogo.parentNode.replaceChild(newAttributionLogo, attributionLogo);

      // Update the attribution link
      if (attributionLink.children[0]) {
        attributionLink.children[0].innerHTML = '© Barikoi | ';
        attributionLink.children[0].href = 'https://www.barikoi.com';
      }

      // Clear the interval after successful updates
      clearInterval(attrIntervalId);
    }
  }, 0);
}


// Check Uniq By Index
export function uniqByIndex(arr, index) {
  if(!arr?.length || index < 0) {
    return []
  }

  let unq = []
  arr.forEach(a => {
    if(index >= 0 && index < a.length) {
      if(unq.length > 0) {
        if(!unq.find(u =>  u[index] === a[index])) {
          unq.push(a)
        }
      
      } else {
        unq.push(a)
      }  
    }
  })
  
  return unq
}

// Decode Valhalla encoded shapes to LineStrings
// This is adapted from the implementation in Project-OSRM
// https://github.com/DennisOSRM/Project-OSRM-Web/blob/master/WebContent/routing/OSRM.RoutingGeometry.js
export function decodeVhRouteShape(str, precision) {
  let index = 0
  let lat = 0
  let lng = 0
  let coordinates = []
  let shift = 0
  let result = 0
  let byte = null
  let latitude_change
  let longitude_change
  let factor = Math.pow(10, precision || 6)

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null
    shift = 0
    result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1))

    shift = result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1))

    lat += latitude_change
    lng += longitude_change

    coordinates.push([lng / factor, lat / factor])
  }

  return coordinates
}

// Kepler GL Dataset to Array of Objects
export function datasetToRowObject(dataset, filtered=true) {
  if(!dataset) {
    return []
  }
  
  const fields = dataset?.fields ?? []

  const allData = dataset?.dataContainer?._rows ?? []

  const filteredIndexForDomain = dataset?.filteredIndexForDomain ?? []
  let rows = []
  
  if(filtered) {
    rows = filteredIndexForDomain.map(i => {
      const row = {}
      fields.forEach(f => {
        row[ f.name ] = allData[ i ][ f.fieldIdx ]
      })
  
      return row
    })

  } else {
    rows = allData.map(d => {
      const row = {}
      fields.forEach(f => {
        row[ f.name ] = d[ f.fieldIdx ] // fieldIdx - 1
      })
  
      return row
    })
  }
  return rows
}