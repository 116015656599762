import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { Box, FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material'

class StyledMultiSelect extends React.PureComponent {
  // On Change
  _onChange = e => {
    const { options, onChange } = this.props
    const value = e?.target?.value ?? []

    const _value = typeof value === 'string' ? value.split(',') : value

    if(_value.includes('None')) {
      return onChange(e, [{ value: 'None', label: 'None' }])
    }

    if(_value.includes('All')) {
      return onChange(e, [{ value: 'All', label: 'All' }])
    }

    // Find Selected Options
    const selectedOptions = options?.filter(o => _value.includes(o.value)) ?? [{ value: 'All', label: 'All' }]

    return onChange(e, selectedOptions)
  }

  render() {
    const { label, value, name, options, disabled, noneOption, allOption } = this.props

    return (
      <FormControl margin='dense' size='small' fullWidth={ true } sx={{ my: '4px' }}>
        <InputLabel
          margin='dense'
          variant='outlined'
          sx={{
            '&.MuiInputLabel-root': {
              mt: '-2px',
              fontSize: '0.8rem',
            },
            '&.MuiInputLabel-shrink': {
              mt: '4px',
              ml: '-4px',
              px: '8px',
              fontSize: '0.8rem',
              background: '#fff'
            }
          }}
        >
          { label }
        </InputLabel>
        <Select
          displayEmpty={ true }
          size='small'
          name={ name }
          value={ value }
          label={ label }
          onChange={ this._onChange }
          disabled={ disabled }
          notched={ false }
          multiple={ true }
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              { selected.map(_value => (
                  <Chip
                    key={ _value }
                    size='small'
                    label={ options?.find(o => o.value === _value)?.label ?? '' }
                    clickable={ true }
                    onMouseDown={e => {
                      e.stopPropagation()
                    }}
                    onDelete={ () => {
                      this._onChange({ target: { value: value.filter(v => v !== _value) } })
                    }}
                  />
                ))
              }
            </Box>
          )}
          sx={{
            mt: '2px',
            maxHeight: value?.length ? 'auto' : '28px',
            fontSize: '0.7rem',
            '& .MuiSelect-icon': {
              fontSize: '1rem'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px !important'
            }
          }}
        >
          { noneOption &&
            <MenuItem key={ -1 } dense={ true } value='None' sx={{ fontSize: '0.7rem' }}><i>{ 'None' }</i></MenuItem>
          }
          { allOption &&
            <MenuItem key={ -2 } dense={ true } value='All' sx={{ fontSize: '0.7rem' }}><i>{ 'All' }</i></MenuItem>
          }

          {
            options?.map((o, i) =>
              <MenuItem
                key={ i }
                dense={ true }
                value={ o?.value ?? '' }
                sx={{
                  fontSize: '0.7rem'
                }}
              >
                { o?.label ?? '' }
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    )
  }
}

// Prop Types
StyledMultiSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  noneOption: PropTypes.bool,
  allOption: PropTypes.bool
}

StyledMultiSelect.defaultProps = {
  label: '',
  value: [],
  name: '',
  onChange: () => null,
  options: [],
  disabled: false,
  noneOption: true,
  allOption: true
}

export default StyledMultiSelect