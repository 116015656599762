import axios from 'axios'
import { utils, writeFile } from 'xlsx'
import { StatActionTypes } from './actionTypes'
import { sortOptionsAsc } from '../../utils/utils'
import { API } from '../../App.config'

//////////
// STAT //
//////////

// Set Is Stat Loading
export function setIsStatLoading(isStatLoading) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_IS_STAT_LOADING, payload: isStatLoading })
  }
}

// Set Selected Stat Tab
export function setSelectedStatTab(selectedStatTab) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_STAT_TAB, payload: selectedStatTab })
  }
}

////////////////
// COMPARISON //
////////////////

// Set Comparison Start Date
export function setComparisonStartDate(comparisonStartDate) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_COMPARISON_START_DATE, payload: comparisonStartDate })
  }
}

// Set Comparison End Date
export function setComparisonEndDate(comparisonEndDate) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_COMPARISON_END_DATE, payload: comparisonEndDate })
  }
}

// Set Selected Comparison Brand
export function setSelectedComparisonBrand(selectedComparisonBrand) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARISON_BRAND, payload: selectedComparisonBrand })
  }
}

// Set Selected Comparison SKU
export function setSelectedComparisonSku(selectedComparisonSku) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARISON_SKU, payload: selectedComparisonSku })
  }
}

// Set Selected Compare Option 1
export function setSelectedCompareOption1(selectedCompareOption1) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARE_OPTION_1, payload: selectedCompareOption1 })
  }
}

// Set Selected Compare Option 2
export function setSelectedCompareOption2(selectedCompareOption2) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARE_OPTION_2, payload: selectedCompareOption2 })
  }
}

// Set Selected Compare Stats 1
export function setSelectedCompareStats1(selectedCompareStats1) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARE_STATS_1, payload: selectedCompareStats1 })
  }
}

// Set Selected Compare Stats 2
export function setSelectedCompareStats2(selectedCompareStats2) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_COMPARE_STATS_2, payload: selectedCompareStats2 })
  }
}

// Get Outlet Compare Stats
export function getOutletCompareStats({ start_date, end_date, outlets, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(outlets.map(o => fetchOutletStats({ start_date, end_date, outlet_id: o.id, brand, sku })))
      .then(res => {
        const outletStatsData = res?.map(o => o ?? {}) ?? []
        dispatch( setStatsComparisonData(outletStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Route Compare Stats
export function getRouteCompareStats({ start_date, end_date, routes, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(routes.map(r => fetchRouteStats({ start_date, end_date, route_id: r.id, brand, sku })))
      .then(res => {
        const routeStatsData = res?.map(r => r ?? {}) ?? []
        dispatch( setStatsComparisonData(routeStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Town Compare Stats
export function getTownCompareStats({ start_date, end_date, towns, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(towns.map(t => fetchTownStats({ start_date, end_date, town_id: t.id, brand, sku })))
      .then(res => {
        const townStatsData = res?.map(t => t ?? {}) ?? []
        dispatch( setStatsComparisonData(townStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Territory Compare Stats
export function getTerritoryCompareStats({ start_date, end_date, territories, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(territories.map(t => fetchTerritoryStats({ start_date, end_date, territory_id: t.id, brand, sku })))
      .then(res => {
        const territoryStatsData = res?.map(t => t ?? {}) ?? []
        dispatch( setStatsComparisonData(territoryStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Area Compare Stats
export function getAreaCompareStats({ start_date, end_date, areas, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(areas.map(a => fetchAreaStats({ start_date, end_date, area_id: a.id, brand, sku })))
      .then(res => {
        const areaStatsData = res?.map(a => a ?? {}) ?? []
        dispatch( setStatsComparisonData(areaStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Region Compare Stats
export function getRegionCompareStats({ start_date, end_date, regions, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(regions.map(r => fetchRegionStats({ start_date, end_date, region_id: r.id, brand, sku })))
      .then(res => {
        const regionStatsData = res?.map(r => r ?? {}) ?? []
        dispatch( setStatsComparisonData(regionStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Nation Compare Stats
export function getNationCompareStats({ start_date, end_date, nations, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    axios.all(nations.map(n => fetchNationStats({ start_date, end_date, nation_id: n.id, brand, sku })))
      .then(res => {
        const nationStatsData = res?.map(n => n ?? {}) ?? []
        dispatch( setStatsComparisonData(nationStatsData) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStatsComparisonData([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Set Selected Stats Comparison Options
export function setSelectedStatsComparisonOptions(selectedStatsComparisonOptions) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_STATS_COMPARISON_OPTIONS, payload: selectedStatsComparisonOptions })
  }
}

// Set Selected Stats Comparison Field Options
export function setSelectedStatsComparisonFieldOptions(selectedStatsComparisonFieldOptions) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_STATS_COMPARISON_FIELD_OPTIONS, payload: selectedStatsComparisonFieldOptions })
  }
}

// Set Stats Comparison Data
export function setStatsComparisonData(statsComparisonData) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_STATS_COMPARISON_DATA, payload: statsComparisonData })
  }
}

/////////////////////
// DATE RANGE FORM //
/////////////////////

// Set Start Date
export function setStartDate(startDate) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_START_DATE, payload: startDate })
  }
}

// Set End Date
export function setEndDate(endDate) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_END_DATE, payload: endDate })
  }
}

// Set Selected Route Stats Type
export function setSelectedRouteStatsType(selectedRouteStatsType) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_ROUTE_STATS_TYPE, payload: selectedRouteStatsType })
  }
}

// Set SR List
export function setSrList(srList) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SR_LIST, payload: srList })
  }
}

// Set Selected SR
export function setSelectedSr(selectedSr) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_SR, payload: selectedSr })
  }
}

// Get SR List Data
export function getSrList(selectedRoute) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get SR List Data from API
    axios.get(API.GET_SR_LIST, { params: { route_id: selectedRoute?.id ?? '' } })
      .then(res => {
        const { sr_list } = res.data

        // If SR List Invalid
        if(!sr_list?.length) {
          dispatch( setSrList([]) )

          // Set Is Stat Loading
          dispatch( setIsStatLoading(false) )

          return
        }

        const srOptions = sortOptionsAsc(sr_list.map(s => ({ ...s, value: s.id, label: `${ s.name }${ s?.active_route_id === selectedRoute?.id ? ' (active)' : '' }` })))

        dispatch( setSrList(srOptions) )

        // Set Default Sr
        if(srOptions?.length) {
          dispatch( setSelectedSr(srOptions[0] ?? null) )
        }

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setSrList([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Set Selected Town Stats Type
export function setSelectedTownStatsType(selectedTownStatsType) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_TOWN_STATS_TYPE, payload: selectedTownStatsType })
  }
}

// Set DH List
export function setDhList(dhList) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_DH_LIST, payload: dhList })
  }
}

// Set Selected DH
export function setSelectedDh(selectedDh) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_DH, payload: selectedDh })
  }
}

// Get DH List Data
export function getDhList(selectedTown) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get DH List Data from API
    axios.get(API.GET_DH_LIST, { params: { town_id: selectedTown?.id ?? '' } })
      .then(res => {
        const { dh_list } = res.data

        // If DH List Invalid
        if(!dh_list?.length) {
          dispatch( setDhList([]) )

          // Set Is Stat Loading
          dispatch( setIsStatLoading(false) )

          return
        }

        const dhOptions = sortOptionsAsc(dh_list.map(d => ({ ...d, value: d.id, label: d.name })))

        dispatch( setDhList(dhOptions) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setDhList([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

///////////
// Brand //
///////////

// Set Brands
export function setBrands(brands) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_BRANDS, payload: brands })
  }
}

// Set Selected Brand
export function setSelectedBrand(selectedBrand) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_BRAND, payload: selectedBrand })
  }
}

// Get Brands Data
export function getBrands() {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Brands Data from API
    axios.get(API.GET_BRANDS)
      .then(res => {
        const { brands } = res.data

        // If Brands Invalid
        if(!brands?.length) {
          dispatch( setBrands([]) )

          // Set Is Stat Loading
          dispatch( setIsStatLoading(false) )

          return
        }

        const brandOptions = sortOptionsAsc(brands.map((b, i) => ({ ...b, value: i+1, label: b.brand })))

        dispatch( setBrands(brandOptions) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setBrands([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

/////////
// SKU //
/////////

// Set SKU
export function setSku(sku) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SKU, payload: sku })
  }
}

// Set Selected SKU
export function setSelectedSku(selectedSku) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SELECTED_SKU, payload: selectedSku })
  }
}

// Get SKU Data
export function getSku() {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get SKU Data from API
    axios.get(API.GET_SKU)
      .then(res => {
        const { sku } = res.data

        // If SKU Invalid
        if(!sku?.length) {
          dispatch( setSku([]) )

          // Set Is Stat Loading
          dispatch( setIsStatLoading(false) )

          return
        }

        const skuOptions = sortOptionsAsc(sku.map((s, i) => ({ ...s, value: i+1, label: s.sku })))

        dispatch( setSku(skuOptions) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setSku([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

///////////
// STATS //
///////////

// Set Stats
export function setStats(stats) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_STATS, payload: stats })
  }
}

// Get Outlet Stats Data
export function getOutletStats({ start_date, end_date, outlet_id }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Outlet Stats Data from API
    fetchOutletStats({ start_date, end_date, outlet_id })
      .then(outletStats => {
        dispatch( setStats(outletStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Route Stats Data
export function getRouteStats({ start_date, end_date, route_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Route Stats Data from API
    fetchRouteStats({ start_date, end_date, route_id, brand, sku })
      .then(routeStats => {
        dispatch( setStats(routeStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Town Stats Data
export function getTownStats({ start_date, end_date, town_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Town Stats Data from API
    fetchTownStats({ start_date, end_date, town_id, brand, sku })
      .then(townStats => {
        dispatch( setStats(townStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Territory Stats Data
export function getTerritoryStats({ start_date, end_date, territory_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Territory Stats Data from API
    fetchTerritoryStats({ start_date, end_date, territory_id, brand, sku })
      .then(territoryStats => {
        dispatch( setStats(territoryStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Area Stats Data
export function getAreaStats({ start_date, end_date, area_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Area Stats Data from API
    fetchAreaStats({ start_date, end_date, area_id, brand, sku })
      .then(areaStats => {
        dispatch( setStats(areaStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Region Stats Data
export function getRegionStats({ start_date, end_date, region_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Region Stats Data from API
    fetchRegionStats({ start_date, end_date, region_id, brand, sku })
      .then(regionStats => {
        dispatch( setStats(regionStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get Nation Stats Data
export function getNationStats({ start_date, end_date, nation_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get Nation Stats Data from API
    fetchNationStats({ start_date, end_date, nation_id, brand, sku })
      .then(nationStats => {
        dispatch( setStats(nationStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get SR Stats Data
export function getSrStats({ start_date, end_date, sr_id, route_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get SR Stats Data from API
    fetchSrStats({ start_date, end_date, sr_id, route_id, brand, sku })
      .then(({ srStats, srOrders, srVisitedOutlets, srOrderClusters }) => {
        dispatch( setStats(srStats) )
        dispatch( setSrOrders(srOrders) )
        dispatch( setSrVisitedOutlets(srVisitedOutlets) )
        dispatch( setSrOrderClusters(srOrderClusters) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )
        dispatch( setSrOrders([]) )
        dispatch( setSrVisitedOutlets([]) )
        dispatch( setSrOrderClusters([]) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Get DH Stats Data
export function getDhStats({ start_date, end_date, dh_id, town_id, brand, sku }) {
  return dispatch => {
    // Set Is Stat Loading
    dispatch( setIsStatLoading(true) )

    // Get DH Stats Data from API
    fetchDhStats({ start_date, end_date, dh_id, town_id, brand, sku })
      .then(dhStats => {
        dispatch( setStats(dhStats) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )

      })
      .catch(err => {
        console.error(err)

        dispatch( setStats({}) )

        // Set Is Stat Loading
        dispatch( setIsStatLoading(false) )
      })
  }
}

// Export Stats Data in a CSV Format
export function exportStatsAsCsv(stats, fileName) {
  return () => {
    if(!stats || !Object.keys(stats)?.length) {
      return
    }

    const worksheet = utils.json_to_sheet([ stats ])
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, fileName)
    writeFile(workbook, `${ fileName }.csv`, { bookType: 'csv' })
  }
}

// Export Stats Data in a XLSX Format
export function exportStatsAsXlsx(stats, fileName) {
  return () => {
    if(!stats || !Object.keys(stats)?.length) {
      return
    }

    const worksheet = utils.json_to_sheet([ stats ])
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, fileName)
    writeFile(workbook, `${ fileName }.xlsx`, { bookType: 'xlsx' })
  }
}

////////////
// SR ORDERS //
////////////

// Set SR Orders
export function setSrOrders(srOrders) {
  return dispatch => {
    // Sort SR Orders by `in_time` in Descending Order
    const sortedSrOrders = srOrders.sort((o1, o2) => {
      const inTimeO1 = o1?.in_time ?? ''
      const inTimeO2 = o2?.in_time ?? ''

      if(inTimeO1 > inTimeO2) {
        return -1
      }

      if(inTimeO1 < inTimeO2) {
        return 1
      }

      return 0
    })

    dispatch({ type: StatActionTypes.SET_SR_ORDERS, payload: sortedSrOrders })
  }
}

// Set SR Order Columns
export function setSrOrderColumns(srOrderColumns) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SR_ORDER_COLUMNS, payload: srOrderColumns })
  }
}

// Set Is Orders Layer On
export function setIsOrdersLayerOn(isSrOrdersLayerOn) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_IS_SR_ORDERS_LAYER_ON, payload: isSrOrdersLayerOn })
  }
}

// Set SR Visited Outlets
export function setSrVisitedOutlets(srVisitedOutlets) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SR_VISITED_OUTLETS, payload: srVisitedOutlets })
  }
}

// Set SR Order Clusters
export function setSrOrderClusters(srOrderClusters) {
  return dispatch => {
    dispatch({ type: StatActionTypes.SET_SR_ORDER_CLUSTERS, payload: srOrderClusters })
  }
}

///////////////
// UTILITIES //
///////////////
// Fetch Outlet Stats From API
export function fetchOutletStats({ start_date, end_date, outlet_id, brand, sku }) {
  // Fetch Outlet Stats Data from API
  return axios.get(API.GET_OUTLET_STATS, { params: { start_date, end_date, outlet_id, brand, sku } })
    .then(res => {
      const { outlet_stats } = res.data
      return outlet_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Route Stats From API
export function fetchRouteStats({ start_date, end_date, route_id, brand, sku }) {
  // Fetch Route Stats Data from API
  return axios.get(API.GET_ROUTE_STATS, { params: { start_date, end_date, route_id, brand, sku } })
    .then(res => {
      const { route_stats } = res.data
      return route_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Town Stats From API
export function fetchTownStats({ start_date, end_date, town_id, brand, sku }) {
  // Fetch Town Stats Data from API
  return axios.get(API.GET_TOWN_STATS, { params: { start_date, end_date, town_id, brand, sku } })
    .then(res => {
      const { town_stats } = res.data
      return town_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Territory Stats From API
export function fetchTerritoryStats({ start_date, end_date, territory_id, brand, sku }) {
  // Fetch Territory Stats Data from API
  return axios.get(API.GET_TERRITORY_STATS, { params: { start_date, end_date, territory_id, brand, sku } })
    .then(res => {
      const { territory_stats } = res.data
      return territory_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Area Stats From API
export function fetchAreaStats({ start_date, end_date, area_id, brand, sku }) {
  // Fetch Area Stats Data from API
  return axios.get(API.GET_AREA_STATS, { params: { start_date, end_date, area_id, brand, sku } })
    .then(res => {
      const { area_stats } = res.data
      return area_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Region Stats From API
export function fetchRegionStats({ start_date, end_date, region_id, brand, sku }) {
  // Fetch Region Stats Data from API
  return axios.get(API.GET_REGION_STATS, { params: { start_date, end_date, region_id, brand, sku } })
    .then(res => {
      const { region_stats } = res.data
      return region_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch Nation Stats From API
export function fetchNationStats({ start_date, end_date, nation_id, brand, sku }) {
  // Fetch Nation Stats Data from API
  return axios.get(API.GET_NATION_STATS, { params: { start_date, end_date, nation_id, brand, sku } })
    .then(res => {
      const { nation_stats } = res.data
      return nation_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Fetch SR Stats From API
export function fetchSrStats({ start_date, end_date, sr_id, route_id, brand, sku }) {
  // Fetch SR Stats Data from API
  return axios.get(API.GET_SR_STATS, { params: { start_date, end_date, sr_id, route_id, brand, sku } })
    .then(res => {
      const { sr_stats, sr_orders, sr_visited_outlets, sr_order_clusters } = res.data
      return {
        srStats: sr_stats ?? {},
        srOrders: sr_orders ?? [],
        srVisitedOutlets: sr_visited_outlets ?? [],
        srOrderClusters: sr_order_clusters ?? []
      }
    })
    .catch(err => {
      throw err
    })
}

// Fetch DH Stats From API
export function fetchDhStats({ start_date, end_date, dh_id, town_id, brand, sku }) {
  // Fetch DH Stats Data from API
  return axios.get(API.GET_DH_STATS, { params: { start_date, end_date, dh_id, town_id, brand, sku } })
    .then(res => {
      const { dh_stats } = res.data
      return dh_stats ?? {}
    })
    .catch(err => {
      throw err
    })
}

// Add Supporting Columns To Export Stats
export function addSupportingColumns(stats, props) {
  const supportingStats = {}
  const skipSelections = [ 'All', 'None' ]

  if(props?.selectedNation && !skipSelections.includes(props?.selectedNation?.label ?? '')) {
    supportingStats.nation = props?.selectedNation?.label ?? ''
  }

  if(props?.selectedRegion && !skipSelections.includes(props?.selectedRegion?.label ?? '')) {
    supportingStats.region = props?.selectedRegion?.label ?? ''
  }

  if(props?.selectedArea && !skipSelections.includes(props?.selectedArea?.label ?? '')) {
    supportingStats.area = props?.selectedArea?.label ?? ''
  }

  if(props?.selectedTerritory && !skipSelections.includes(props?.selectedTerritory?.label ?? '')) {
    supportingStats.territory = props?.selectedTerritory?.label ?? ''
  }

  if(props?.selectedTown && !skipSelections.includes(props?.selectedTown?.label ?? '')) {
    supportingStats.town = props?.selectedTown?.label ?? ''
  }

  if(props?.selectedDh && !skipSelections.includes(props?.selectedDh?.label ?? '')) {
    supportingStats.distribution_house = props?.selectedDh?.label ?? ''
  }

  if(props?.selectedRoute && !skipSelections.includes(props?.selectedRoute?.label ?? '')) {
    supportingStats.route = props?.selectedRoute?.label ?? ''
  }

  if(props?.selectedSr && !skipSelections.includes(props?.selectedSr?.label ?? '')) {
    supportingStats.sales_representative = props?.selectedSr?.label ?? ''
  }

  if(props?.selectedBrand && !skipSelections.includes(props?.selectedBrand?.label ?? '')) {
    supportingStats.brand = props?.selectedBrand?.label ?? ''
  }

  if(props?.selectedSku && !skipSelections.includes(props?.selectedSku?.label ?? '')) {
    supportingStats.sku = props?.selectedSku?.label ?? ''
  }

  if(props?.startDate && props?.endDate) {
    supportingStats.start_date = props?.startDate ?? 'N/A'
    supportingStats.end_date = props?.endDate ?? 'N/A'
  }

  return { ...supportingStats, ...stats }
}