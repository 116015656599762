import { SocketActionTypes } from '../actions/actionTypes'

const initialState = {
  srPositionData: null,
  srOrderDelayData: null
}

const socketReducer = (state=initialState, action) => {
  switch(action.type) {
    case SocketActionTypes.SET_SR_POSITION_DATA:
      return {
        ...state,
        srPositionData: action.payload
      }
    
    case SocketActionTypes.SET_SR_ORDER_DELAY_DATA:
      return {
        ...state,
        srOrderDelayData: action.payload
      }
    
    default:
      return state
  }
}

export default socketReducer