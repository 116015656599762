import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSelect from '../common/StyledSelect'

// Import Actions & Methods
import { getColorByOptions, setSelectedColorBy, colorOutletsBy, colorRoutesBy, colorTownsBy, colorTerritoriesBy, colorAreasBy, colorRegionsBy, colorNationsBy } from '../../redux/actions/mapActions'

class SelectColorBy extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    // Load Color By Options
    dispatch( getColorByOptions() )
  }

  componentDidUpdate(prevProps) {
    const { selectedColorBy } = this.props

    // If selectedColorBy Changes in props
    if(prevProps?.selectedColorBy?.value !== selectedColorBy?.value && selectedColorBy?.value) {
      this._onSelectedColorByUpdate(selectedColorBy)
    }
  }

  // On Color By Change
  _onColorByChange = (e, selectedColorBy) => {
    const { dispatch } = this.props
    dispatch( setSelectedColorBy(selectedColorBy) )
  }

  // On Selected Color By Update
  _onSelectedColorByUpdate = selectedColorBy => {
    const { dispatch, selectedRoute, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation } = this.props

    // If Route Selected
    if(selectedRoute?.value && selectedRoute?.value !== 'None' && selectedRoute?.value !== 'All') {
      return dispatch( colorOutletsBy(selectedColorBy) )
    }

    // If Town Selected
    if(selectedTown?.value && selectedTown?.value !== 'None' && selectedTown?.value !== 'All') {
      return dispatch( colorRoutesBy(selectedColorBy) )
    }
    
    // If Territory Selected
    if(selectedTerritory?.value && selectedTerritory?.value !== 'None' && selectedTerritory?.value !== 'All') {
      return dispatch( colorTownsBy(selectedColorBy) )
    }
    
    // If Area Selected
    if(selectedArea?.value && selectedArea?.value !== 'None' && selectedArea?.value !== 'All') {
      return dispatch( colorTerritoriesBy(selectedColorBy) )
    }
    
    // If Region Selected
    if(selectedRegion?.value && selectedRegion?.value !== 'None' && selectedRegion?.value !== 'All') {
      return dispatch( colorAreasBy(selectedColorBy) )
    }

    // If Nation Selected
    if(selectedNation?.value && selectedNation?.value !== 'None' && selectedNation?.value !== 'All') {
      return dispatch( colorRegionsBy(selectedColorBy) )
    }

    // If Nation Selected All
    if(selectedNation?.value && selectedNation?.value === 'All') {
      return dispatch( colorNationsBy(selectedColorBy) )
    }
  }

  render() {
    const { colorByOptions, selectedColorBy, selectedRoute } = this.props

    return (
      <Box>
        <StyledSelect
          label='Color By'
          name='select-color-by'
          value={ selectedColorBy?.value ?? '' }
          onChange={ this._onColorByChange }
          options={
            (selectedRoute?.value && selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None') ?     colorByOptions?.filter(cb => cb?.value !== 'strike_rate_level') ?? [] :
            colorByOptions?.filter(cb => cb?.value !== 'channel_name' && cb?.value !== 'clp' && cb?.value !== 'target_achieved_level') ?? []
          }
          allOption={ false }
        />
      </Box>
    )
  }
}

// Prop Types
SelectColorBy.propTypes = {
  colorByOptions: PropTypes.array,
  selectedColorBy: PropTypes.object,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  selectedTerritory: PropTypes.object,
  selectedArea: PropTypes.object,
  selectedRegion: PropTypes.object,
  selectedNation: PropTypes.object
}

SelectColorBy.defaultProps = {
  colorByOptions: [],
  selectedColorBy: null,
  selectedRoute: null,
  selectedTown: null,
  selectedTerritory: null,
  selectedArea: null,
  selectedRegion: null,
  selectedNation: null
}

const mapStateToProps = state => ({
  colorByOptions: state?.map?.colorByOptions ?? [],
  selectedColorBy: state?.map?.selectedColorBy ?? null,
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  selectedArea: state?.nav?.selectedArea ?? null,
  selectedRegion: state?.nav?.selectedRegion ?? null,
  selectedNation: state?.nav?.selectedNation ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(SelectColorBy)