import { TraceActionTypes } from '../actions/actionTypes'
import { unionArrayOfObjects } from '../../utils/utils'

const initialState = {
  isTraceLoading: false,
  isTraceOn: false,
  traceUsers: []
}

const traceReducer = (state=initialState, action) => {
  switch(action.type) {
    case TraceActionTypes.SET_IS_TRACE_LOADING:
      return {
        ...state,
        isTraceLoading: action.payload
      }
    
    case TraceActionTypes.SET_IS_TRACE_ON:
      return {
        ...state,
        isTraceOn: action.payload
      }
    
    case TraceActionTypes.SET_TRACE_USERS:
      return {
        ...state,
        traceUsers: action.payload
      }
    
    case TraceActionTypes.UPDATE_TRACE_USERS:
      const traceUsers = action.payload.filter(u => u.longitude && u.latitude)
      return {
          ...state,
          traceUsers: unionArrayOfObjects(state.traceUsers, traceUsers, 'user_id')
      }
    
    default:
      return state
  }
}

export default traceReducer