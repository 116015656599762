import dayjs from 'dayjs'
import { StatActionTypes } from '../actions/actionTypes'

const initialState = {
  isStatLoading: false,
  startDate: `${ dayjs().format('YYYY-MM-DD') } 00:00:00`,
  endDate: `${ dayjs().format('YYYY-MM-DD') } 23:59:59`,
  selectedRouteStatsType: 'route',
  srList: [],
  selectedSr: null,
  selectedTownStatsType: 'town',
  dhList: [],
  selectedDh: null,
  brands: [],
  selectedBrand: { value: 'All', label: 'All' },
  sku: [],
  selectedSku: { value: 'All', label: 'All' },
  stats: {},
  selectedStatTab: 0,
  comparisonStartDate: `${ dayjs().format('YYYY-MM-DD') } 00:00:00`,
  comparisonEndDate: `${ dayjs().format('YYYY-MM-DD') } 23:59:59`,
  selectedComparisonBrand: { value: 'All', label: 'All' },
  selectedComparisonSku: { value: 'All', label: 'All' },
  selectedCompareOption1: null,
  selectedCompareOption2: null,
  selectedCompareStats1: {},
  selectedCompareStats2: {},
  srOrders: [],
  srOrderColumns: [
    { field: 'order_no', headerName: 'Order No', minWidth: 120, sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'order_date', headerName: 'Order Date', minWidth: 120, sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'sales_type', headerName: 'Sales Type', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'section_id', headerName: 'Section ID', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'in_time', headerName: 'In Time', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'out_time', headerName: 'Out Time', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'sku_code', headerName: 'SKU Code', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'sku_name', headerName: 'SKU', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'brand', headerName: 'Brand', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'quantity', headerName: 'Quantity', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'trade_price', headerName: 'Trade Price', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'gross_value', headerName: 'Gross Value', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'outlet_name', headerName: 'Outlet Name', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'duration', headerName: 'Duration', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } },
    { field: 'distance_from_outlet_m', headerName: 'Distance From Outlet (m)', sortable: true, autoHeight: true, wrapText: true, cellStyle: { lineHeight: 1.2, fontSize: '0.7rem', display: 'flex', alignItems: 'center', wordBreak: 'normal' } }
  ],
  isSrOrdersLayerOn: false,
  srVisitedOutlets: [],
  selectedStatsComparisonOptions: [],
  selectedStatsComparisonFieldOptions: [],
  statsComparisonData: [],
  srOrderClusters: []
}

const statReducer = (state=initialState, action) => {
  switch(action.type) {
    case StatActionTypes.SET_IS_STAT_LOADING:
      return {
        ...state,
        isStatLoading: action.payload
      }
    
    case StatActionTypes.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload
      }
    
    case StatActionTypes.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload
      }
    
    case StatActionTypes.SET_BRANDS:
      return {
        ...state,
        brands: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: action.payload
      }
    
    case StatActionTypes.SET_SKU:
      return {
        ...state,
        sku: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_SKU:
      return {
        ...state,
        selectedSku: action.payload
      }
    
    case StatActionTypes.SET_STATS:
      return {
        ...state,
        stats: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_ROUTE_STATS_TYPE:
      return {
        ...state,
        selectedRouteStatsType: action.payload
      }
    
    case StatActionTypes.SET_SR_LIST:
      return {
        ...state,
        srList: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_SR:
      return {
        ...state,
        selectedSr: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_TOWN_STATS_TYPE:
      return {
        ...state,
        selectedTownStatsType: action.payload
      }
    
    case StatActionTypes.SET_DH_LIST:
      return {
        ...state,
        dhList: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_DH:
      return {
        ...state,
        selectedDh: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_STAT_TAB:
      return {
        ...state,
        selectedStatTab: action.payload
      }
    
    case StatActionTypes.SET_COMPARISON_START_DATE:
      return {
        ...state,
        comparisonStartDate: action.payload
      }
    
    case StatActionTypes.SET_COMPARISON_END_DATE:
      return {
        ...state,
        comparisonEndDate: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARISON_BRAND:
      return {
        ...state,
        selectedComparisonBrand: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARISON_SKU:
      return {
        ...state,
        selectedComparisonSku: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARE_OPTION_1:
      return {
        ...state,
        selectedCompareOption1: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARE_OPTION_2:
      return {
        ...state,
        selectedCompareOption2: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARE_STATS_1:
      return {
        ...state,
        selectedCompareStats1: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_COMPARE_STATS_2:
      return {
        ...state,
        selectedCompareStats2: action.payload
      }
    
    case StatActionTypes.SET_SR_ORDERS:
      return {
        ...state,
        srOrders: action.payload
      }
    
    case StatActionTypes.SET_SR_ORDER_COLUMNS:
      return {
        ...state,
        srOrderColumns: action.payload
      }
    
    case StatActionTypes.SET_IS_SR_ORDERS_LAYER_ON:
      return {
        ...state,
        isSrOrdersLayerOn: action.payload
      }
    
    case StatActionTypes.SET_SR_VISITED_OUTLETS:
      return {
        ...state,
        srVisitedOutlets: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_STATS_COMPARISON_OPTIONS:
      return {
        ...state,
        selectedStatsComparisonOptions: action.payload
      }
    
    case StatActionTypes.SET_SELECTED_STATS_COMPARISON_FIELD_OPTIONS:
      return {
        ...state,
        selectedStatsComparisonFieldOptions: action.payload
      }
    
    case StatActionTypes.SET_STATS_COMPARISON_DATA:
      return {
        ...state,
        statsComparisonData: action.payload
      }
    
    case StatActionTypes.SET_SR_ORDER_CLUSTERS:
      return {
        ...state,
        srOrderClusters: action.payload
      }
    
    default:
      return state
  }
}

export default statReducer