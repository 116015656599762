import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsTraceOn, activateTrace, deactivateTrace } from '../../redux/actions/traceActions'

class TraceControl extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { dispatch, isTraceOn } = this.props

    // If `isTraceOn` changes in props
    if(prevProps.isTraceOn !== isTraceOn) {
      if(isTraceOn) {
        // Activate Trace
        dispatch( activateTrace() )

      } else {
        // Deactivate Trace
        dispatch( deactivateTrace() )
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props

    // Deactivate Trace
    dispatch( deactivateTrace() )
  }

  // On Trace Control Change
  _onTraceControlChange = (e, value) => {
    const { dispatch } = this.props
    dispatch( setIsTraceOn(value) )
  }
  
  render() {
    const { isTraceOn } = this.props

    return (
      <Box>
        <StyledSwitch
          label='Trace SO'
          checked={ isTraceOn }
          value={ isTraceOn }
          onChange={ this._onTraceControlChange }
        />
      </Box>
    )
  }
}

// Prop Types
TraceControl.propTypes = {
  isTraceOn: PropTypes.bool,
  dispatch: PropTypes.func
}

TraceControl.defaultProps = {
  isTraceOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isTraceOn: state?.trace?.isTraceOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TraceControl)