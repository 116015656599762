import axios from 'axios'
import { AuthActionTypes } from './actionTypes'
import { AUTH } from '../../App.config'

// Get Trace Company Token From Local Storage
export function getTraceCompanyToken() {
  const traceCompanyToken = localStorage.getItem('traceCompanyToken')
  return traceCompanyToken
}

// Set Is Authenticated
export function setIsAuthenticated(isAuthenticated) {
  return dispatch =>  {
    dispatch({
      type: AuthActionTypes.SET_IS_AUTHENTICATED,
      payload: isAuthenticated
    })
  }
}

// Set Is Auth Validating
export function setIsAuthValidating(isValidating) {  
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_IS_AUTH_VALIDATING,
      payload: isValidating
    })
  }
}

// Set Email Id
export function setEmailId(emailId) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_EMAIL_ID,
      payload: emailId
    })
  }
}

// Set Password
export function setPassword(password) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_PASSWORD,
      payload: password
    })
  }
}

// Set Token
export function setToken(token) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_TOKEN,
      payload: token
    })
  }
}

// Set User
export function setUser(user) {
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_USER,
      payload: user
    })
  }
}

// Set Is Auth Error
export function setAuthError(isAuthError) {  
  return dispatch => {
    dispatch({
      type: AuthActionTypes.SET_AUTH_ERROR,
      payload: isAuthError
    })
  }
}

///// LOGIN /////
// Login Action
export function login(user) {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsAuthValidating(true) )

    axios.post(AUTH.LOGIN_API, user)
      .then(res => {
        if(!res?.data?.token) {
          throw new Error('Token Not Found')
        }

        // Validate User
        const token = res.data.token
        dispatch( validateUser(token) )

      })
      .catch(err => {
        console.error(err)

        // Dispatch `authReducer` Values to Redux State
        dispatch( setIsAuthenticated(false) )
        dispatch( setToken('') )
        dispatch( setUser({}) )
        dispatch( setAuthError(err?.response?.data?.message ?? err?.message ?? '') )

        // Set `isValidating`
        dispatch( setIsAuthValidating(false) )
      })
  }
}

// User Validation
export function validateUser(token) {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsAuthValidating(true) )

    axios.get(AUTH.GET_USER_API, { headers: { Authorization: `Bearer ${ token }` } })
      .then(res => {
        if(!res?.data?.user) {
          throw new Error('User Not Found')
        }

        // Save `token` & `user` to localStorage
        const user = res.data.user
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))

        // Dispatch authReducer Values to Redux State
        dispatch( setIsAuthenticated(true) )
        dispatch( setToken(token) )
        dispatch( setUser(user) )
        dispatch( setAuthError('') )
        dispatch( setEmailId('') )
        dispatch( setPassword('') )

        return

      })
      .then(() => {
        // Trace Company Login
        return axios.post(AUTH.TRACE_LOGIN_API, { email: AUTH.TRACE_LOGIN_USER, password: AUTH.TRACE_LOGIN_PASSWORD })
          .then(res => {
            if(!res?.data?.data) {
              throw new Error('Unauthorized Trace Company')
            }

            // Save `traceCompanyToken` & `traceCompany` to localStorage
            const traceCompanyToken = res.data.data
            const traceCompany = res?.data?.company ?? {}
            localStorage.setItem('traceCompanyToken', traceCompanyToken)
            localStorage.setItem('traceCompany', JSON.stringify(traceCompany))

            // Set `isValidating`
            dispatch( setIsAuthValidating(false) )
            
          })
          .catch(err => {
            console.error(err)

            // Set `isValidating`
            dispatch( setIsAuthValidating(false) )
          })
      })
      .catch(err => {
        console.error(err)

        // Dispatch authReducer Values to Redux State
        dispatch( setIsAuthenticated(false) )
        dispatch( setToken('') )
        dispatch( setUser({}) )
        dispatch( setAuthError(err?.response?.data?.message ?? err?.message ?? '') )

        // If no token in `localStorage`
        if(!localStorage.getItem('token')) {
          dispatch( setAuthError('') )
        }

        // Clear localStorage
        localStorage.clear()

        // Set `isValidating`
        dispatch( setIsAuthValidating(false) )
      })
  }
}

// Logout Action
export function logout() {
  return dispatch => {
    // Set `isValidating`
    dispatch( setIsAuthValidating(true) )

    // Clear localStorage
    localStorage.clear()

    // Dispatch `authReducer` Values to Redux State
    dispatch( setIsAuthenticated(false) )
    dispatch( setToken('') )
    dispatch( setUser({}) )
    dispatch( setAuthError('') )
    
    window.location.href = '/login'
  }
}