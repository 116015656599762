import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getBkoiOutlets, setBkoiOutlets, setIsBkoiTownOutletsOn } from '../../redux/actions/navActions'
import { clearBkoiOutletsFromMap } from '../../redux/actions/mapActions'

const BkoiTownOutletsControl = ({ dispatch, selectedTown, isBkoiTownOutletsOn }) => {
  useEffect(() => {
    return () => {
      dispatch( setIsBkoiTownOutletsOn(false) )

      // Clear Bkoi Town Outlets
      dispatch( setBkoiOutlets([]) )
      dispatch( clearBkoiOutletsFromMap() )
    }
  }, [ dispatch ])

  useEffect(() => {
    if(isBkoiTownOutletsOn) {
      if(selectedTown?.value !== 'All' && selectedTown?.value !== 'None') {
        // Load Bkoi Town Outlets
        dispatch( getBkoiOutlets({ town_id: selectedTown?.id ?? '' }) )
      }

    } else {
      // Clear Bkoi Town Outlets
      dispatch( setBkoiOutlets([]) )
      dispatch( clearBkoiOutletsFromMap() )
    }
  }, [ dispatch, isBkoiTownOutletsOn, selectedTown ])

  const _onBkoiTownOutletsControlChange = (e, value) => {
    dispatch( setIsBkoiTownOutletsOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Bkoi Town Outlets'
        checked={ isBkoiTownOutletsOn }
        value={ isBkoiTownOutletsOn }
        onChange={ _onBkoiTownOutletsControlChange }
      />
    </Box>
  )
}

// Prop Types
BkoiTownOutletsControl.propTypes = {
  selectedTown: PropTypes.object,
  isBkoiTownOutletsOn: PropTypes.bool,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  isBkoiTownOutletsOn: state?.nav?.isBkoiTownOutletsOn ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(BkoiTownOutletsControl)