import { MAP } from '../App.config'

const config = {
  "version": "v1",
  "config": {
    "visState": {
      "filters": [],
      "layers": [],
      "interactionConfig": {
        "tooltip": {
          "fieldsToShow": {},
          "compareMode": false,
          "compareType": "absolute",
          "enabled": true
        },
        "brush": {
          "size": 0.5,
          "enabled": false
        },
        "geocoder": {
          "enabled": false
        },
        "coordinate": {
          "enabled": false
        }
      },
      "layerBlending": "normal",
      "splitMaps": [],
      "animationConfig": {
        "currentTime": null,
        "speed": 1
      }
    },
    "mapState": {
      "bearing": 0,
      "dragRotate": false,
      "latitude": 23.793733913654076,
      "longitude": 90.36872236556212,
      "pitch": 0,
      "zoom": 10.7759970069689,
      "isSplit": false
    },
    "mapStyle": {
      "styleType": "barikoi-light",
      "topLayerGroups": {},
      "visibleLayerGroups": {
        "label": true,
        "road": true,
        "building": true,
        "water": true,
        "land": true
      },
      "threeDBuildingColor": [
        219.7513799623817,
        221.61367979257136,
        223.47597962276103
      ],
      "mapStyles": {
        "barikoi-light": {
          "accessToken": null,
          "custom": true,
          "id": "barikoi-light",
          "label": "Light",
          "url": `https://map.barikoi.com/styles/osm-liberty/style.json?key=${MAP.API_KEY}`
        },
        "barikoi-dark": {
          "accessToken": null,
          "custom": true,
          "id": "barikoi-dark",
          "label": "Dark",
          "url": `https://map.barikoi.com/styles/barikoi-dark/style.json?key=${MAP.API_KEY}`
        }
      }
    }
  }
}

export default config