import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getBkoiClusters, setBkoiClusters, setIsBkoiClustersOn } from '../../redux/actions/navActions'
import { clearBkoiClustersFromMap } from '../../redux/actions/mapActions'

const BkoiClustersControl = ({ dispatch, selectedTown, isBkoiClustersOn }) => {
  useEffect(() => {
    return () => {
      dispatch( setIsBkoiClustersOn(false) )

      // Clear Bkoi Clusters
      dispatch( setBkoiClusters([]) )
      dispatch( clearBkoiClustersFromMap() )
    }
  }, [ dispatch ])

  useEffect(() => {
    if(isBkoiClustersOn) {
      if(selectedTown?.value !== 'All' && selectedTown?.value !== 'None') {
        // Load Barikoi Clusters
        dispatch( getBkoiClusters(selectedTown) )
      }

    } else {
      // Clear Bkoi Clusters
      dispatch( setBkoiClusters([]) )
      dispatch( clearBkoiClustersFromMap() )
    }
  }, [ dispatch, isBkoiClustersOn, selectedTown ])

  const _onBkoiClustersControlChange = (e, value) => {
    dispatch( setIsBkoiClustersOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Clusters'
        checked={ isBkoiClustersOn }
        value={ isBkoiClustersOn }
        onChange={ _onBkoiClustersControlChange }
      />
    </Box>
  )
}

// Prop Types
BkoiClustersControl.propTypes = {
  selectedTown: PropTypes.object,
  isBkoiClustersOn: PropTypes.bool,
  dispatch: PropTypes.func
}


const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  isBkoiClustersOn: state?.nav?.isBkoiClustersOn ?? false,
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(BkoiClustersControl)