import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Typography, Slider, TextField, Divider } from '@mui/material'

// Import Actions & Methods
import { setVisitDistanceThreshold, setFastOrderDuration, setLateOrderThresholdTime } from '../../redux/actions/configsActions'
import { setStats } from '../../redux/actions/statActions'
import { getMinMaxValue, formatDuration, formatDistance } from '../../utils/utils'

const Configs = ({ dispatch, srOrders, stats, visitDistanceThreshold, fastOrderDuration, lateOrderThresholdTime, selectedRouteStatsType, selectedRoute, selectedTown }) => {
  const [ visitDistanceThresholdRangeMarks, setVisitDistanceThresholdRangeMarks ] = useState([])
  const [ fastOrderDurationRangeMarks ] = useState([
    { value: 0, label: formatDuration(0) },
    { value: 60, label: formatDuration(60) },
    { value: 120, label: formatDuration(120) },
    { value: 180, label: formatDuration(180) },
    { value: 240, label: formatDuration(240) },
    { value: 300, label: formatDuration(300) },
    { value: 360, label: formatDuration(360) },
    { value: 420, label: formatDuration(420) },
    { value: 480, label: formatDuration(480) },
    { value: 540, label: formatDuration(540) },
    { value: 600, label: formatDuration(600) }
  ])

  useEffect(() => {
    if(srOrders?.length) {
      // Set Visit Distance Threshold Range Marks
      const newMinMaxVisitDistance = getMinMaxValue(srOrders, 'distance_from_outlet_m', true)
      const newVisitDistanceThresholdRangeMarks = _getVisitDistanceRangeMarks(newMinMaxVisitDistance, Math.ceil((newMinMaxVisitDistance[1] - newMinMaxVisitDistance[0]) / 6))
      setVisitDistanceThresholdRangeMarks(newVisitDistanceThresholdRangeMarks)
    }
  }, [ srOrders ])

  useEffect(() => {
    return () => {
      dispatch( setFastOrderDuration(120) )
    }
  }, [])

  // On Late Order Threshold Time Change
  const _onLateOrderThresholdTimeChange = lateOrderThresholdTime => {
    dispatch( setLateOrderThresholdTime(lateOrderThresholdTime?.target?.value ? `${ lateOrderThresholdTime?.target?.value }:00` : '17:00:00') )
  }

  // On Visit Distance Threshold Change
  const _onVisitDistanceThresholdChange = (e, newVisitDistanceThreshold) => {
    dispatch( setVisitDistanceThreshold(newVisitDistanceThreshold) )
  }

  // On Visit Distance Threshold Committed
  const _onVisitDistanceThresholdCommitted = (e, newVisitDistanceThreshold) => {
    const visitedOutletsCount = _getVisitedOutletsCount(srOrders, newVisitDistanceThreshold)
    dispatch( setStats({ ...stats, visited_outlets: visitedOutletsCount }) )
  }

  // Get Visited Outlets Count
  const _getVisitedOutletsCount = (srOrders, newVisitDistanceThreshold) => {
    if(!srOrders?.length) {
      return 0
    }

    const visitedOutlets = srOrders.reduce((acc, o) => {
      if(o?.distance_from_outlet_m <= newVisitDistanceThreshold) {
        return acc.add(o.outlet_id)
      }

      return acc.add(-1)
    }, new Set())

    return visitedOutlets.size > 0 ? visitedOutlets.size : 0
  }

  // Get Visit Distance Range Marks
  const _getVisitDistanceRangeMarks = (minMaxRangeValue, step) => {
    const marks = [{ value: minMaxRangeValue[ 0 ], label: formatDistance(minMaxRangeValue[ 0 ]) }]
    for(let i = minMaxRangeValue[ 0 ] + step; i > minMaxRangeValue[ 0 ] && Math.ceil(i) < minMaxRangeValue[ 1 ]; i += step) {
      marks.push({
        value: Math.ceil(i),
        label: formatDistance(Math.ceil(i))
      })
    }

    marks.push({ value: minMaxRangeValue[ 1 ], label: formatDistance(minMaxRangeValue[ 1 ]) })

    return marks
  }

  // Visit Distance Threshold Value Label Format
  const _visitDistanceValueLabelFormat = value => {
    return formatDistance(value)
  }

  // On Fast Order Duration Change
  const _onFastOrderDurationChange = (e, newFastOrderDuration) => {
    dispatch( setFastOrderDuration(newFastOrderDuration) )
  }

  // On Fast Order Duration Committed
  const _onFastOrderDurationCommitted = (e, newFastOrderDuration) => {
    dispatch( setFastOrderDuration(newFastOrderDuration) )
  }

  // Fast Order Duration Value Label Format
  const _fastOrderDurationValueLabelFormat = value => {
    return formatDuration(value)
  }

  return (
    <Box sx={ containerStyles }>
      <Box sx={{ ...bodyContainerStyles, maxHeight: `${ document.querySelector('.right-panel-body')?.clientHeight - 32 }px` }}>
        { srOrders?.length > 0 &&
          <>
            <Box padding='0.8rem' width='100%'>
              <Typography
                align='left'
                noWrap={ true }
                variant='caption'
                color='textSecondary'
              >
                { 'Visit Distance Threshold' }
              </Typography>
              <Slider
                name='visit-distance-threshold'
                size='small'
                marks={ visitDistanceThresholdRangeMarks }
                max={ visitDistanceThresholdRangeMarks?.length > 0 ? visitDistanceThresholdRangeMarks[ visitDistanceThresholdRangeMarks.length - 1 ].value : 0 }
                min={ visitDistanceThresholdRangeMarks?.length > 0 ? visitDistanceThresholdRangeMarks[ 0 ].value : 0 }
                step={ 50 }
                value={ visitDistanceThreshold }
                valueLabelDisplay='auto'
                valueLabelFormat={ _visitDistanceValueLabelFormat }
                onChange={ _onVisitDistanceThresholdChange }
                onChangeCommitted={ _onVisitDistanceThresholdCommitted }
                sx={{
                  margin: 0,
                  marginTop: '-0.5rem',
                  '& .MuiSlider-markLabel': {
                    marginTop: '-0.8rem'
                  }
                }}
              />
            </Box>
            <Divider variant='fullWidth' />
          </>
        }

        { ((selectedRouteStatsType === 'route' && selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None') || ((selectedTown?.value !== 'All' && selectedTown?.value !== 'None') && (selectedRoute?.value === 'All' || selectedRoute?.value === 'None'))) &&
          <>
            <Box padding='0.8rem' width='100%'>
              <Typography
                align='left'
                noWrap={ true }
                variant='caption'
                color='textSecondary'
              >
                { 'Fast Order Duration' }
              </Typography>
              <Slider
                name='fast-order-duration'
                size='small'
                marks={ fastOrderDurationRangeMarks }
                max={ fastOrderDurationRangeMarks?.length > 0 ? fastOrderDurationRangeMarks[ fastOrderDurationRangeMarks.length - 1 ].value : 0 }
                min={ fastOrderDurationRangeMarks?.length > 0 ? fastOrderDurationRangeMarks[ 0 ].value : 0 }
                step={ 10 }
                value={ fastOrderDuration }
                valueLabelDisplay='auto'
                valueLabelFormat={ _fastOrderDurationValueLabelFormat }
                onChange={ _onFastOrderDurationChange }
                onChangeCommitted={ _onFastOrderDurationCommitted }
                sx={{
                  margin: 0,
                  marginTop: '-0.5rem',
                  '& .MuiSlider-markLabel': {
                    marginTop: '-0.8rem'
                  }
                }}
              />
            </Box>
            <Divider variant='fullWidth' />
          </>
        }

        { ((selectedRouteStatsType === 'route' && selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None') || ((selectedTown?.value !== 'All' && selectedTown?.value !== 'None') && (selectedRoute?.value === 'All' || selectedRoute?.value === 'None'))) &&
          <Box padding='0.8rem' width='100%'>
            <TextField
              name='late-order-threshold-time'
              label='Late Order Threshold Time'
              type='time'
              size='small'
              fullWidth={ true }
              value={ lateOrderThresholdTime }
              onChange={ _onLateOrderThresholdTimeChange }
              InputProps={{
                notched: false,
                sx: {
                  padding: '0.25rem',
                  maxHeight: '28px',
                  fontSize: '0.7rem'
                }
              }}
              InputLabelProps={{
                sx: {
                  '&.MuiInputLabel-root': {
                    mt: '-2px',
                    fontSize: '0.8rem'
                  },
                  '&.MuiInputLabel-shrink': {
                    mt: '2px',
                    ml: '-4px',
                    px: '8px',
                    fontSize: '0.8rem',
                    background: '#fff'
                  }
                }
              }}
            />
          </Box>
        }
      </Box>
    </Box>
  )
}

// JSS Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between'
}

const bodyContainerStyles = {
  padding: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between',
  overflow: 'auto'
}

// Prop Types
Configs.propTypes = {
  srOrders: PropTypes.array,
  stats: PropTypes.object,
  visitDistanceThreshold: PropTypes.number,
  fastOrderDuration: PropTypes.number,
  lateOrderThresholdTime: PropTypes.string,
  selectedRouteStatsType: PropTypes.string,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  dispatch: PropTypes.func
}


const mapStateToProps = state => ({
  srOrders: state?.stat?.srOrders ?? [],
  stats: state?.stat?.stats ?? {},
  visitDistanceThreshold: state?.configs?.visitDistanceThreshold ?? 50,
  fastOrderDuration: state?.configs?.fastOrderDuration ?? 120,
  lateOrderThresholdTime: state?.configs?.lateOrderThresholdTime ?? '17:00:00',
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? 'route',
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Configs)