import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pointsWithinPolygon } from '@turf/turf'
import { exportObjectsAsCsv } from '../../utils/utils'
import { BKOI_CLUSTERS } from '../../App.config'

const FixedTooltip = ({ clicked, layers, fieldsToShow, datasets, outlets, bkoiOutlets }) => {
  // Get Layer Object
  const _getLayer = clicked => {
    const layer = layers.find(l => l?.id === clicked?.layer?.id)

    if(!layer) {
      return null
    }

    return layer
  }

  // Get Target Dataset
  const _getDataset = (clicked, datasets) => {
    const layer = _getLayer(clicked)
    const dataset = datasets[ layer?.config?.dataId ?? '' ]
    return dataset
  }

  // Get Value Suffix
  const _getValueSuffix = (field, clicked, fieldIndex) => {
    if(!clicked?.object?.properties && !clicked?.object?.data?.length) {
      return ''
    }

    // If Quantity & Number
    if(field.name.includes('quantity') || field.name.includes('sales')) {
      if((clicked?.object?.properties && typeof clicked?.object?.properties[ field.name ] === 'number') || (clicked?.object?.data?.length && typeof clicked?.object?.data[ fieldIndex ] === 'number')) {
        return ' pcs'
      }
    }

    // Gross Value & Number
    if(field.name.includes('gross_value')) {
      if((clicked?.object?.properties && typeof clicked?.object?.properties[ field.name ] === 'number') || (clicked?.object?.data?.length && typeof clicked?.object?.data[ fieldIndex ] === 'number')) {
        return ' BDT'
      }
    }

    // If Percentile Or Strike Rate & Number
    if(field.name.includes('percentile') || field.name.includes('strike_rate')) {
      if((clicked?.object?.properties && typeof clicked?.object?.properties[ field.name ] === 'number') || (clicked?.object?.data?.length && typeof clicked?.object?.data[ fieldIndex ] === 'number')) {
        return '%'
      }
    }

    return ''
  }

  // Render Key Value Pairs
  const _renderKeyValuePairs = () => {
    const layer = _getLayer(clicked)

    if(!layer) {
      return null
    }

    const _fieldsToShow = fieldsToShow[ layer?.config?.dataId ?? '' ]

    const domList = _fieldsToShow.map((f, i) => {
      const fieldIndex = _getDataset(clicked, datasets)?.fields?.findIndex(fld => fld.name === f.name)
      const fieldValue = _getDataset(clicked, datasets)?.dataContainer?._rows[clicked?.index][fieldIndex]

      // If Not Found
      if(fieldIndex < 0 || (!clicked?.object && !clicked?.data)) {
        return null
      }

      const suffix = _getValueSuffix(f, clicked, fieldIndex)

      return (
        <tr key={ i } style={ trStyles }>
          <td style={ tdKeyStyles }>{ f.name }</td>
          <td style={ tdValueStyles }>
            { clicked?.object?.properties ?
              `${ clicked?.object?.properties[ f.name ] }${ suffix }` :
              fieldValue ?? 'N/A'
              // clicked?.object?.data?.length ?
              // `${ clicked?.object?.data[ fieldIndex ] }${ suffix }` :
              // 'N/A'
            }
          </td>
        </tr>
      )
    })

    return domList
  }

  // Get Outlets Within Cluster
  const _getOutletsWithinCluster = (clusterGeoJson, outlets, ignoreLocation=false) => {
    const points = {
      type: 'FeatureCollection',
      features: outlets.map(o => {
        const properties = {}
        Object.keys(o).forEach(k => {
          if(k !== 'longitude' && k !== 'latitude') {
            properties[k] = o[k]
          }
        })

        const feature = {
          type: 'Feature',
          properties,
          geometry: {
            type: 'Point',
            coordinates: [ o.longitude, o.latitude ]
          }
        }

        return feature
      })
    }

    const ptsWithin = pointsWithinPolygon(points, clusterGeoJson)

    const exportableOutlets = ptsWithin?.features?.map(p => {
      const outlet = { ...p.properties }

      if(ignoreLocation) {
        return outlet
      }

      outlet['longitude'] = p.geometry.coordinates[0]
      outlet['latitude'] = p.geometry.coordinates[1]

      return outlet
    }) ?? []

    return exportableOutlets
  }

  // On Cluster Download
  const _onClusterDownload = () => {
    const clusterGeoJson = clicked?.object?.geometry ?? null

    if(clusterGeoJson) {
      // Get Town Outlets Within Cluster
      const clusterTownOutlets = _getOutletsWithinCluster(clusterGeoJson, outlets)

      // Get Bkoi Town Outlets Within Cluster
      const clusterBkoiTownOutlets = _getOutletsWithinCluster(clusterGeoJson, bkoiOutlets, true)

      // Export
      exportObjectsAsCsv(clusterTownOutlets, `${ clusterGeoJson?.properties?.name ?? '' }_Outlets`)
      exportObjectsAsCsv(clusterBkoiTownOutlets, `${ clusterGeoJson?.properties?.name ?? '' }_Bkoi_Outlets`)
    }
  }

  if(!clicked) {
    return null
  }

  return (
    <div style={ containerStyles }>
      <div style={ layerLabelContainerStyles }>
        <svg viewBox='0 0 64 64' width='12px' height='12px' style={{ fill: 'currentcolor' }}>
          <path d="M50.88,43.52a3.2,3.2,0,0,1,0,5.86L34.56,56.52a6.42,6.42,0,0,1-5.13,0L13.12,49.37a3.2,3.2,0,0,1,0-5.86l4.62-2a6,6,0,0,0,1.48,1l2.16.95-7,3.05,16.32,7.14a3.19,3.19,0,0,0,2.56,0L49.6,46.44l-7-3.05,2.16-.95a6,6,0,0,0,1.48-.95Zm0-14.39a3.2,3.2,0,0,1,0,5.86L34.56,42.13a6.42,6.42,0,0,1-5.13,0L13.12,35a3.2,3.2,0,0,1,0-5.86l4.62-2a6,6,0,0,0,1.48,1l2.16.95-7,3.05L30.72,39.2a3.19,3.19,0,0,0,2.56,0L49.6,32.06l-7-3.05,2.16-.95a6,6,0,0,0,1.48-.95ZM13.12,20.6a3.2,3.2,0,0,1,0-5.86L29.44,7.6a6.39,6.39,0,0,1,5.13,0l16.32,7.14a3.2,3.2,0,0,1,0,5.86L34.56,27.74a6.39,6.39,0,0,1-5.13,0Z" />
        </svg>
        <span style={ layerLabelStyles }>
          { _getLayer(clicked)?.config?.label ?? 'N/A' }
        </span>
      </div>

      <div style={ keyValueContainerStyles }>
        <table style={ tableStyles }>
          <tbody style={ tBodyStyles }>
            { _renderKeyValuePairs() }
          </tbody>
        </table>

        { _getLayer(clicked)?.config?.dataId === BKOI_CLUSTERS.DATA_ID &&
          <button onClick={ _onClusterDownload }>
            { 'Download' }
          </button>
        }
      </div>
    </div>
  )
}

// JSS Styles
const containerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: '8px 12px',
  position: 'absolute',
  left: 0,
  bottom: 0,
  background: '#c0c0c0',
  maxWidth: '320px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  borderRadius: '4px'
}

const layerLabelContainerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center'
}

const layerLabelStyles = {
  boxSizing: 'border-box',
  margin: 0,
  marginLeft: '4px',
  marginBottom: '-4px',
  padding: 0,
  fontSize: '0.7rem'
}

const keyValueContainerStyles = {
  boxSizing: 'border-box',
  margin: 0,
  marginTop: '8px',
  padding: 0,
  maxHeight: '200px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch'
}

const tableStyles = {
  display: 'table'
}

const tBodyStyles = {
  display: 'table-row-group'
}

const trStyles = {
  display: 'table-row'
}

const tdKeyStyles = {
  display: 'table-cell',
  padding: 0,
  paddingRight: '8px',
  fontSize: '12px'
}

const tdValueStyles = {
  display: 'table-cell',
  padding: 0,
  paddingLeft: '8px',
  maxWidth: '280px',
  whiteSpace: 'wrap',
  fontSize: '12px'
}

// Prop Types
FixedTooltip.propTypes = {
  clicked: PropTypes.object,
  layers: PropTypes.array,
  fieldsToShow: PropTypes.object,
  datasets: PropTypes.object,
  outlets: PropTypes.array,
  bkoiOutlets: PropTypes.array
}

const mapStateToProps = state => ({
  clicked: state?.keplerGl?.map?.visState?.clicked ?? null,
  layers: state?.keplerGl?.map?.visState?.layers ?? [],
  fieldsToShow: state?.keplerGl?.map?.visState?.interactionConfig?.tooltip?.config?.fieldsToShow ?? {},
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {},
  outlets: state?.nav?.outlets ?? [],
  bkoiOutlets: state?.nav?.bkoiOutlets ?? []
})

export default connect(mapStateToProps)(FixedTooltip)