import { ConfigsActionTypes } from './actionTypes'

// Set Visit Distance Threshold
export function setVisitDistanceThreshold(visitDistanceThreshold) {
  return dispatch => {
    dispatch({ type: ConfigsActionTypes.SET_VISIT_DISTANCE_THRESHOLD, payload: visitDistanceThreshold })
  }
}

// Set Fast Order Duration
export function setFastOrderDuration(fastOrderDuration) {
  return dispatch => {
    dispatch({ type: ConfigsActionTypes.SET_FAST_ORDER_DURATION, payload: fastOrderDuration })
  }
}

// Set Late Order Threshold Time
export function setLateOrderThresholdTime(lateOrderThresholdTime) {
  return dispatch => {
    dispatch({ type: ConfigsActionTypes.SET_LATE_ORDER_THRESHOLD_TIME, payload: lateOrderThresholdTime })
  }
}