import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Typography } from '@mui/material'
import StyledSelect from '../common/StyledSelect'

// Import Actions & Methods
import { filterOutlets } from '../../redux/actions/mapActions'

class FilterByClp extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { selectedRoute, selectedClp } = this.props

    // If `selectedClp` Changes In State
    if(prevProps.selectedClp?.value !== selectedClp?.value && selectedClp?.value) {
      this._onSelectedClpUpdate(selectedClp, selectedRoute)
    }
  }

  // On Selected CLP Update
  _onSelectedClpUpdate = (selectedClp, selectedRoute) => {
    const { dispatch } = this.props
    
    // If Single Route Selected
    if(selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value && selectedClp?.value) {
      // Filter Outlets by CLP
      dispatch(
        filterOutlets({
          name: 'clp',
          value: selectedClp?.value === 'All' ?
            [] :
            selectedClp?.value === 'None' ?
            [ '' ] :
            [ selectedClp?.clp ]
        })
      )
    }
  }

  render() {
    const { clpOptions, selectedClp, onSelectedClpChange } = this.props

    return (
      <Box>
        <Typography
          align='left'
          noWrap={ true }
          variant='caption'
          color='textSecondary'
        >
          { 'by CLP' }
        </Typography>
        <StyledSelect
          name='select-clp'
          label='Select CLP'
          options={ clpOptions }
          value={ selectedClp?.value ?? '' }
          onChange={ onSelectedClpChange }
        />
      </Box>
    )
  }
}

// Prop Types
FilterByClp.propTypes = {
  selectedRoute: PropTypes.object,
  clpOptions: PropTypes.array,
  selectedClp: PropTypes.object,
  onSelectedClpChange: PropTypes.func,
  dispatch: PropTypes.func
}

FilterByClp.defaultProps = {
  selectedRoute: null,
  clpOptions: [],
  selectedClp: null,
  onSelectedClpChange: () => null,
  dispatch: () => null
}

const mapStateToProps = state => ({
  selectedRoute: state?.nav?.selectedRoute ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterByClp)