import { injectComponents, ModalContainerFactory, SidePanelFactory, LayerHoverInfoFactory } from '@kepler.gl/components'
// import CustomLayerHoverInfo from './CustomLayerHoverInfo'

// Define null factory to not render any unneсessary components
const NullComponent = () => null
const NullComponentFactory = () => NullComponent

const KeplerGL = injectComponents([
  [ ModalContainerFactory, NullComponentFactory ],
  [ SidePanelFactory, NullComponentFactory ],
  // [ LayerHoverInfoFactory, NullComponentFactory ]
])

export default KeplerGL