import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

// Import Components
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import AdapterDayjs from '@mui/lab/AdapterDayjs'

import App from './App'

// Import Styles
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// Import Store
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <LocalizationProvider dateAdapter={ AdapterDayjs }>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
)