import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { getOutlets, setOutlets, setIsTownOutletsOn } from '../../redux/actions/navActions'
import { clearOutletsFromMap } from '../../redux/actions/mapActions'

const TownOutletsControl = ({ dispatch, selectedTown, isTownOutletsOn, startDate, endDate, selectedBrand, selectedSku, lateOrderThresholdTime, fastOrderDuration }) => {
  useEffect(() => {
    return () => {
      dispatch( setIsTownOutletsOn(false) )

      // Clear Town Outlets
      dispatch( setOutlets([]) )
      dispatch( clearOutletsFromMap() )
    }
  }, [ dispatch ])

  useEffect(() => {
    if(isTownOutletsOn) {
      if(selectedTown?.value !== 'All' && selectedTown?.value !== 'None') {
        const brand = selectedBrand?.label && selectedBrand?.value !== 'None' && selectedBrand?.value !== 'All' ? selectedBrand.label : ''
        const sku = selectedSku?.label && selectedSku?.value !== 'None' && selectedSku?.value !== 'All' ? selectedSku.label : ''

        // Load Town Outlets
        dispatch(
          getOutlets({ town_id: selectedTown?.id ?? '', start_date: startDate, end_date: endDate, brand, sku, late_hour: lateOrderThresholdTime, fast_order_duration: fastOrderDuration })
        )
      }

    } else {
      // Clear Town Outlets
      dispatch( setOutlets([]) )
      dispatch( clearOutletsFromMap() )
    }
  }, [ dispatch, isTownOutletsOn, selectedTown, startDate, endDate, selectedBrand, selectedSku, lateOrderThresholdTime, fastOrderDuration ])

  const _onTownOutletsControlChange = (e, value) => {
    dispatch( setIsTownOutletsOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='Town Outlets'
        checked={ isTownOutletsOn }
        value={ isTownOutletsOn }
        onChange={ _onTownOutletsControlChange }
      />
    </Box>
  )
}

// Prop Types
TownOutletsControl.propTypes = {
  selectedTown: PropTypes.object,
  isTownOutletsOn: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedBrand: PropTypes.object,
  selectedSku: PropTypes.object,
  lateOrderThresholdTime: PropTypes.string,
  fastOrderDuration: PropTypes.number,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  selectedTown: state?.nav?.selectedTown ?? null,
  isTownOutletsOn: state?.nav?.isTownOutletsOn ?? false,
  startDate: state?.stat?.startDate ?? '',
  endDate: state?.stat?.endDate ?? '',
  selectedBrand: state?.stat?.selectedBrand ?? null,
  selectedSku: state?.stat?.selectedSku ?? null,
  lateOrderThresholdTime: state?.configs?.lateOrderThresholdTime ?? '17:00:00',
  fastOrderDuration: state?.configs?.fastOrderDuration ?? 120
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TownOutletsControl)