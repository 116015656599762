import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Paper } from '@mui/material'
import { Store, TrendingUp } from 'lucide-react';
import StyledTabsMenu from '../common/StyledTabsMenu'
import Stats from './Stats'
import OutletsPanel from './OutletsPanel'

// Import Actions & Methods
import { setSelectedStatTab } from '../../redux/actions/statActions'

class RightPanel extends React.PureComponent {
  // On Selected Stat Tab Change
  _onStatTabChange = (e, newValue) => {
    const { dispatch } = this.props
    dispatch( setSelectedStatTab(newValue) )
  }

  render() {
    const { selectedStatTab, srOrders, selectedRoute, selectedTown } = this.props

    return (
      <Box sx={{ height: '100%' }}>
        <Paper className='right-panel-body' variant='outlined' sx={ paperStyles }>
          <Box height='32px' width='100%'>
            <StyledTabsMenu
              elevation={ 2 }
              tabOptions={
                [
                  { label: 'Statistics', icon: TrendingUp },
                  { label: 'Outlets', icon: Store },
                ].filter(o => srOrders?.length || (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) || (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value) || o.label !== 'Configs')
              }
              iconSize='0.8rem'
              value={ selectedStatTab }
              onChange={ this._onStatTabChange }
            />
          </Box>

          { selectedStatTab === 0 &&
            <Stats />
          }

          { selectedStatTab === 1 &&
            <OutletsPanel />
          }
        </Paper>
      </Box>
    )
  }
}

// Styles
const paperStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch'
}

// Prop Types
RightPanel.propTypes = {
  selectedStatTab: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  srOrders: PropTypes.array,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  dispatch: PropTypes.func
}

RightPanel.defaultProps = {
  selectedStatTab: 0,
  srOrders: [],
  selectedRoute: null,
  selectedTown: null,
  dispatch: () => null
}

const mapStatToProps = state => ({
  selectedStatTab: state?.stat?.selectedStatTab ?? 0,
  srOrders: state?.stat?.srOrders ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStatToProps, mapDispatchToProps)(RightPanel)