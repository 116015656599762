import PropTypes from 'prop-types'

// Import Components
import { Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material'

const StyledToggleButtonGroup = ({ value, onChange, buttonOptions, disabled }) => {
  // On Change
  const _onChange = (e, newValue) => {
    if (newValue !== null) {
      const selectedOption = buttonOptions?.find(option => option?.value === newValue) ?? null
      onChange(selectedOption)
    }
  }

  return (
    <Box sx={ containerStyles }>
      <ToggleButtonGroup
        fullWidth={ true }
        size='small'
        value={ value }
        exclusive={ true }
        onChange={ _onChange }
        aria-label='filter-outlets'
        color='primary'
        disabled={ disabled }
        sx={{ height: '28px' }}
      >
        {
          buttonOptions.map((b, i) => (
            <ToggleButton
              key={ i }
              fullWidth={ true }
              size='small'
              value={ b.value }
              aria-label={ b.label }
              disabled={ b?.disabled ?? false }
            >
              <Tooltip title={ b?.label ?? '' }>
                <Box width='100%' height='20px' marginTop='2px'>
                  <b.icon size={16} />
                </Box>
              </Tooltip>
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
    </Box>
  )
}

// JSS Styles
const containerStyles = {
  width: '100%'
}

// Prop Types
StyledToggleButtonGroup.propTypes = {
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func,
  buttonOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    label: PropTypes.string,
    icon: PropTypes.any
  })),
  disabled: PropTypes.bool
}


export default StyledToggleButtonGroup