import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box, Typography } from '@mui/material'
import StyledMultiSelect from '../common/StyledMultiSelect'

// Import Actions & Methods
import { filterOutlets, getChannels, setSelectedChannels } from '../../redux/actions/mapActions'

class FilterByChannel extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props

    // Get Channel Options
    dispatch(getChannels())
  }

  componentDidUpdate(prevProps) {
    const { selectedChannels, selectedRoute, selectedTown } = this.props

    // If `selectedChannels` Changes In Props
    if(prevProps.selectedChannels?.length !== selectedChannels?.length) {
      this._onSelectedChannelsUpdate(selectedChannels, selectedRoute, selectedTown)
    }
  }

  // On Selected Channels Update
  _onSelectedChannelsUpdate = (selectedChannels, selectedRoute, selectedTown) => {
    const { dispatch } = this.props
    
    // If Single Route Selected
    if((selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value) || (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value)) {
      // Filter Outlets by Channel
      dispatch(
        filterOutlets({
          name: 'channel_name',
          value: !selectedChannels?.length || selectedChannels?.find(sc => sc.value === 'All') ?
            [] :
            selectedChannels?.find(sc => sc.value === 'None') ?
            [ '' ] :
            selectedChannels?.map(sc => sc.name) ?? [ '' ]
        })
      )
    }
  }

  // On Selected Channels Change
  _onSelectedChannelsChange = (e, selectedChannels) => {
    const { dispatch } = this.props
    dispatch( setSelectedChannels(selectedChannels) )
  }

  // Filter Channel Options To Show Only Available Channels
  _filterChannelsByOutletsData = (channels, outletsDataset) => {
    if(!outletsDataset || !Object.keys(outletsDataset)?.length) {
      return []
    }

    const fields = outletsDataset?.fields ?? []
    const rows = outletsDataset?.allData ?? []

    const transformedData = rows.map(r => {
      const transformedRow = {}
      fields.forEach(f => {
        transformedRow[f.name] = r[f.tableFieldIndex - 1]
      })

      return transformedRow
    })

    const filteredChannels = channels.filter(c => transformedData?.find(td => td.channel_name === c.name) !== undefined)

    return filteredChannels
  }

  render() {
    const { channels, selectedChannels, datasets } = this.props
    const filteredChannels = this._filterChannelsByOutletsData(channels, datasets[ OUTLETS.DATA_ID ] ?? {})

    return (
      <Box>
        <Typography
          align='left'
          noWrap={ true }
          variant='caption'
          color='textSecondary'
        >
          { 'by Channel' }
        </Typography>
        <StyledMultiSelect
          label='Select Channels'
          options={ filteredChannels }
          value={
            selectedChannels
              ?.filter(o => filteredChannels.find(c => c.value === o.value && c.label === o.label))
              ?.map(d => d.value) ?? []
          }
          onChange={ this._onSelectedChannelsChange }
          noneOption={ false }
          allOption={ false }
        />
      </Box>
    )
  }
}

// Prop Types
FilterByChannel.propTypes = {
  channels: PropTypes.array,
  selectedChannels: PropTypes.array,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  datasets: PropTypes.object,
  dispatch: PropTypes.func
}

FilterByChannel.defaultProps = {
  channels: [],
  selectedChannels: [],
  selectedRoute: null,
  selectedTown: null,
  datasets: {},
  dispatch: () => null
}

const mapStateToProps = state => ({
  channels: state?.map?.channels ?? [],
  selectedChannels: state?.map?.selectedChannels ?? [],
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {}
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterByChannel)