import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { OUTLETS } from '../../App.config'

// Import Components
import { Box } from '@mui/material'
import ExportMenu from '../common/ExportMenu'

// Import Actions & Methods
import { exportObjectsAsCsv, exportObjectsAsXlsx } from '../../utils/utils'

class ExportOutlets extends React.PureComponent {
  // On CSV Export
  _onCsvExport = () => {
    const { datasets } = this.props
    const outletsDataset = datasets[ OUTLETS.DATA_ID ]
    const transformedOutlets = this._transformOutletsData(outletsDataset)
    const fileName = this._getFileName()

    // Export CSV
    exportObjectsAsCsv(transformedOutlets, fileName)

    // Bkoi Outlets
    const bkoiOutletsDataset = datasets[ OUTLETS.BKOI_OUTLETS_DATA_ID ]
    if(bkoiOutletsDataset) {
      const transformedBkoiOutlets = this._transformOutletsData(bkoiOutletsDataset, true)
      const bkoiOutletsFileName = 'Barikoi_Outlets'

      // Export CSV
      exportObjectsAsCsv(transformedBkoiOutlets, bkoiOutletsFileName)
    }
  }

  // On XLSX Export
  _onXlsxExport = () => {
    const { datasets } = this.props
    const outletsDataset = datasets[ OUTLETS.DATA_ID ]
    const transformedOutlets = this._transformOutletsData(outletsDataset)
    const fileName = this._getFileName()

    // Export XLSX
    exportObjectsAsXlsx(transformedOutlets, fileName)

    // Bkoi Outlets
    const bkoiOutletsDataset = datasets[ OUTLETS.BKOI_OUTLETS_DATA_ID ]
    if(bkoiOutletsDataset) {
      const transformedBkoiOutlets = this._transformOutletsData(bkoiOutletsDataset, true)
      const bkoiOutletsFileName = 'Barikoi_Outlets'

      // Export XLSX
      exportObjectsAsXlsx(transformedBkoiOutlets, bkoiOutletsFileName)
    }
  }

  // Transform Outlets Data
  _transformOutletsData = (outletsDataset, ignoreLocation=false) => {
    if(!outletsDataset || !Object.keys(outletsDataset)?.length) {
      return []
    }

    const fields = outletsDataset?.fields ?? []
    const rows = outletsDataset?.allData ?? []
    const filteredIndex = outletsDataset?.filteredIndexForDomain ?? []

    const transformedData = filteredIndex.map(index => {
      const row = rows[index]
      const transformedRow = {}
      fields.forEach(f => {
        if(ignoreLocation && (f.name === 'longitude' || f.name === 'latitude')) {
          return
        }

        transformedRow[f.name] = row[f.tableFieldIndex - 1]
      })

      return transformedRow
    })

    return transformedData
  }

  // Get File Name
  _getFileName = () => {
    const { selectedRoute } = this.props
    const skipSelections = [ 'All', 'None' ]

    if(selectedRoute && !skipSelections.includes(selectedRoute?.label ?? '')) {
      const fileName = `Route_${ selectedRoute?.label ?? 'NA' }_Outlets`
      return fileName.length > 31 ? 'Exported_Outlets' : fileName
    }

    return 'Exported_Outlets'
  }

  render() {
    const { disabled } = this.props

    return (
      <Box>
        <ExportMenu
          buttonId='outlets-export-button'
          buttonText='Export Outlets'
          menuId='outlets-export-menu'
          disabled={ disabled }
          onExportCsv={ this._onCsvExport }
          onExportXlsx={ this._onXlsxExport }
        />
      </Box>
    )
  }
}

// Prop Types
ExportOutlets.propTypes = {
  datasets: PropTypes.object,
  selectedRoute: PropTypes.object,
  disabled: PropTypes.bool
}

ExportOutlets.defaultProps = {
  datasets: {},
  selectedRoute: null,
  disabled: false
}

const mapStateToProps = state => ({
  datasets: state?.keplerGl?.map?.visState?.datasets ?? {},
  selectedRoute: state?.nav?.selectedRoute ?? null
})

export default connect(mapStateToProps)(ExportOutlets)