import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box } from '@mui/material'
import StyledSwitch from '../common/StyledSwitch'

// Import Actions & Methods
import { setIsAllDistributionHousesOn, getAllDistributionHouses, setAllDistributionHouses } from '../../redux/actions/navActions'
import { clearAllDistributionHousesFromMap } from '../../redux/actions/mapActions'

const AllDistributionHousesControl = ({ dispatch, isAllDistributionHousesOn }) => {
  // If isAllDistributionHousesOn changes in props
  useEffect(() => {
    if(isAllDistributionHousesOn) {
      dispatch( getAllDistributionHouses() )

    } else {
      dispatch( clearAllDistributionHousesFromMap() )
      dispatch( setAllDistributionHouses([]) )
    }
  }, [ isAllDistributionHousesOn ])

  useEffect(() => {
    return () => {
      dispatch( clearAllDistributionHousesFromMap() )
      dispatch( setAllDistributionHouses([]) )
    }
  }, [])

  // On All Distribution Houses Control Change
  const _onAllDistributionHousesControlChange = (e, value) => {
    dispatch( setIsAllDistributionHousesOn(value) )
  }

  return (
    <Box>
      <StyledSwitch
        label='All DH'
        checked={ isAllDistributionHousesOn }
        value={ isAllDistributionHousesOn }
        onChange={ _onAllDistributionHousesControlChange }
      />
    </Box>
  )
}

// Prop Types
AllDistributionHousesControl.propTypes = {
  isAllDistributionHousesOn: PropTypes.bool,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  isAllDistributionHousesOn: state?.nav?.isAllDistributionHousesOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(AllDistributionHousesControl)