import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { TRACE } from '../../App.config'

// Import Components
import { Box } from '@mui/material'
import ExportMenu from '../common/ExportMenu'

// Import Actions & Methods
import { getTraceCompanyToken } from '../../redux/actions/authActions'
import { setIsTraceLoading, transformTraceApiData } from '../../redux/actions/traceActions'
import { exportObjectsAsCsv, exportObjectsAsXlsx } from '../../utils/utils'

class ExportSo extends React.PureComponent {
  // On CSV Export
  _onCsvExport = () => {
    const { dispatch } = this.props

    // Set Is Trace Loading
    dispatch( setIsTraceLoading(true) )

    this._getSoList()
      .then(soList => {
        // Export SO List
        exportObjectsAsCsv(soList, 'Exported_SO')

        // Set Is Trace Loading
        dispatch( setIsTraceLoading(false) )
      })
      .catch(err => {
        console.error(err)

        // Set Is Trace Loading
        dispatch( setIsTraceLoading(false) )
      })
  }

  // On XLSX Export
  _onXlsxExport = () => {
    const { dispatch } = this.props

    // Set Is Trace Loading
    dispatch( setIsTraceLoading(true) )

    this._getSoList()
      .then(soList => {
        // Export SO List
        exportObjectsAsXlsx(soList, 'Exported_SO')

        // Set Is Trace Loading
        dispatch( setIsTraceLoading(false) )
      })
      .catch(err => {
        console.error(err)

        // Set Is Trace Loading
        dispatch( setIsTraceLoading(false) )
      })
  }

  // Get SO List
  _getSoList = () => {
    const { isFixedRoutesOn } = this.props

    // Get Auth Token
    const authToken = getTraceCompanyToken()

    // Hit API to get the latest Position for all
    return axios.get(TRACE.TRACE_API, { headers: { Authorization: `Bearer ${ authToken }` } })
      .then(res => {
        // Update Trace Data First Time From API
        const newData = res.data.users

        // Transform Data
        return transformTraceApiData(newData, isFixedRoutesOn, false)
          .then(transformedNewData => {
            return transformedNewData.map(t => {
              const newT = { ...t }

              newT.last_online = newT.updated_at
              newT.trace_user_id = newT.user_id

              delete newT.updated_at
              delete newT.user_id
              delete newT.icon
              delete newT.route_id

              return newT
            })
          })
          .catch(err => {
            throw err
          })
      })
      .catch(err => {
        throw err
      })
  }

  render() {
    const { disabled } = this.props

    return (
      <Box>
        <ExportMenu
          buttonId='so-export-button'
          buttonText='Export SO'
          menuId='so-export-menu'
          disabled={ disabled }
          onExportCsv={ this._onCsvExport }
          onExportXlsx={ this._onXlsxExport }
        />
      </Box>
    )
  }
}

// Prop Types
ExportSo.propTypes = {
  disabled: PropTypes.bool,
  isFixedRoutesOn: PropTypes.bool,
  dispatch: PropTypes.func
}

ExportSo.defaultProps = {
  disabled: false,
  isFixedRoutesOn: false,
  dispatch: () => null
}

const mapStateToProps = state => ({
  isFixedRoutesOn: state?.nav?.isFixedRoutesOn ?? false
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ExportSo)