import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { Box, Divider } from '@mui/material'
import ExportMenu from '../common/ExportMenu'
import CommonStats from './CommonStats'
import SrOrdersTable from './SrOrdersTable'

// Import Actions & Methods
import { exportStatsAsCsv, exportStatsAsXlsx, addSupportingColumns, setSelectedSr, setStats, setSrOrders, setSrVisitedOutlets, setSrOrderClusters } from '../../redux/actions/statActions'
import { setSelectedChannels, setSelectedStrikeRate } from '../../redux/actions/mapActions'
import { setVisitDistanceThreshold } from '../../redux/actions/configsActions'

class Stats extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { dispatch, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, startDate, endDate, selectedBrand, selectedSku, selectedTownStatsType, selectedDh, selectedRouteStatsType, selectedSr } = this.props

    // If `selectedRoute` Changes in Props
    if(prevProps?.selectedRoute?.value !== selectedRoute?.value) {
      dispatch( setSelectedSr(null) )
    }

    // If Nav Selection Changes in Props
    if(
      prevProps?.selectedNation?.value !== selectedNation?.value ||
      prevProps?.selectedRegion?.value !== selectedRegion?.value ||
      prevProps?.selectedArea?.value !== selectedArea?.value ||
      prevProps?.selectedTerritory?.value !== selectedTerritory?.value ||
      prevProps?.selectedTown?.value !== selectedTown?.value ||
      prevProps?.selectedRoute?.value !== selectedRoute?.value ||
      prevProps?.startDate !== startDate ||
      prevProps?.endDate !== endDate ||
      prevProps?.selectedBrand?.value !== selectedBrand?.value ||
      prevProps?.selectedSku?.value !== selectedSku?.value ||
      prevProps?.selectedTownStatsType !== selectedTownStatsType ||
      prevProps?.selectedDh?.value !== selectedDh?.value ||
      prevProps?.selectedRouteStatsType !== selectedRouteStatsType ||
      prevProps?.selectedSr?.value !== selectedSr?.value
    ) {
      dispatch( setStats({}) )
      dispatch( setStats({}) )
      dispatch( setSrOrders([]) )
      dispatch( setSrVisitedOutlets([]) )
      dispatch( setSrOrderClusters([]) )
      dispatch( setVisitDistanceThreshold(50) )
      dispatch( setSelectedChannels([{ value: 'All', label: 'All' }]) )
      dispatch( setSelectedStrikeRate([ 0, 100 ]) )
    }
  }

  // On Export CSV
  _onExportCsv = () => {
    const { dispatch, stats, startDate, endDate, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr, selectedBrand, selectedSku } = this.props

    // If Stats Valid
    if(!Object.keys(stats)?.length) {
      return
    }

    const completeStats = addSupportingColumns(stats, { startDate, endDate, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr, selectedBrand, selectedSku })
    const fileName = this._generateExportFileName({ selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr })
    dispatch( exportStatsAsCsv(completeStats, fileName) )
  }

  // On Export XLSX
  _onExportXlsx = () => {
    const { dispatch, stats, startDate, endDate, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr, selectedBrand, selectedSku } = this.props

    // If Stats Valid
    if(!Object.keys(stats)?.length) {
      return
    }

    const completeStats = addSupportingColumns(stats, { startDate, endDate, selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr, selectedBrand, selectedSku })
    const fileName = this._generateExportFileName({ selectedNation, selectedRegion, selectedArea, selectedTerritory, selectedTown, selectedRoute, selectedDh, selectedSr })
    dispatch( exportStatsAsXlsx(completeStats, fileName) )
  }

  // Generate Export File Name
  _generateExportFileName = props => {
    const skipSelections = [ 'All', 'None' ]

    if(props?.selectedSr && !skipSelections.includes(props?.selectedSr?.label ?? '')) {
      return props?.selectedSr?.label ?? 'NA'
    }

    if(props?.selectedRoute && !skipSelections.includes(props?.selectedRoute?.label ?? '')) {
      return props?.selectedRoute?.label ?? 'NA'
    }

    if(props?.selectedDh && !skipSelections.includes(props?.selectedDh?.label ?? '')) {
      return props?.selectedDh?.label ?? 'NA'
    }

    if(props?.selectedTown && !skipSelections.includes(props?.selectedTown?.label ?? '')) {
      return props?.selectedTown?.label ?? 'NA'
    }

    if(props?.selectedTerritory && !skipSelections.includes(props?.selectedTerritory?.label ?? '')) {
      return props?.selectedTerritory?.label ?? 'NA'
    }

    if(props?.selectedArea && !skipSelections.includes(props?.selectedArea?.label ?? '')) {
      return props?.selectedArea?.label ?? 'NA'
    }

    if(props?.selectedRegion && !skipSelections.includes(props?.selectedRegion?.label ?? '')) {
      return props?.selectedRegion?.label ?? 'NA'
    }

    if(props?.selectedNation && !skipSelections.includes(props?.selectedNation?.label ?? '')) {
      return props?.selectedNation?.label ?? 'NA'
    }

    return 'Exported_Stats'
  }

  // Get Visited Outlets Count
  _getVisitedOutletsCount = (srOrders, newVisitDistanceThreshold) => {
    if(!srOrders?.length) {
      return 0
    }

    const visitedOutlets = srOrders.reduce((acc, o) => {
      if(o?.distance_from_outlet_m <= newVisitDistanceThreshold) {
        return acc.add(o.outlet_id)
      }

      return acc.add(-1)
    }, new Set())

    return visitedOutlets.size > 0 ? visitedOutlets.size - 1 : 0
  }

  // Re-calculate Stats
  _reCalculateStats = (stats, visitDistanceThreshold) => {
    const newStats = {}

    // Generate Visited Outlets Count Based On Visit Distance Threshold
    if(Object.keys(stats)?.length) {
      // Rename `visit_percentile` with visitDistanceThreshold
      Object.keys(stats).forEach(k => {
        if(k.includes('visit_percentile')) {
          newStats[ `visit_percentile_(${ visitDistanceThreshold }m)` ] = stats[k]
          return
        }

        newStats[ k ] = stats[ k ]
      })

      // Re-calculate visit_percentile
      if(newStats.hasOwnProperty(`visit_percentile_(${ visitDistanceThreshold }m)`)) {
        newStats[ `visit_percentile_(${ visitDistanceThreshold }m)` ] = ((newStats.visited_outlets / newStats.total_outlets) * 100).toFixed(2)
      }
    }

    return newStats
  }

  // Get Common Stats Title
  _getCommonStatsTitle = (selectedRouteStatsType, selectedSr, selectedRoute, selectedTownStatsType, selectedDh, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation) => {
    // If SO Stats, SO and Route Selected
    if(selectedRouteStatsType === 'sr' && (selectedSr?.value !== 'All' && selectedSr?.value !== 'None' && selectedSr?.value) && (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value)) {
      return selectedSr?.label ?? ''
    }

    // If Route Stats and Route Selected
    if(selectedRouteStatsType === 'route' && (selectedRoute?.value !== 'All' && selectedRoute?.value !== 'None' && selectedRoute?.value)) {
      return selectedRoute?.label ?? ''
    }

    // If DH Stats, DH and Town Selected
    if(selectedTownStatsType === 'dh' && (selectedDh?.value !== 'All' && selectedDh?.value !== 'None' && selectedDh?.value) && (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value)) {
      return selectedDh?.label ?? ''
    }

    // If Town Stats and Town Selected
    if(selectedTownStatsType === 'town' && (selectedTown?.value !== 'All' && selectedTown?.value !== 'None' && selectedTown?.value)) {
      return selectedTown?.label ?? ''
    }

    // If Territory Selected
    if(selectedTerritory?.value !== 'All' && selectedTerritory?.value !== 'None' && selectedTerritory?.value) {
      return selectedTerritory?.label ?? ''
    }

    // If Area Selected
    if(selectedArea?.value !== 'All' && selectedArea?.value !== 'None' && selectedArea?.value) {
      return selectedArea?.label ?? ''
    }

    // If Region Selected
    if(selectedRegion?.value !== 'All' && selectedRegion?.value !== 'None' && selectedRegion?.value) {
      return selectedRegion?.label ?? ''
    }

    // If Nation Selected
    if(selectedNation?.value !== 'All' && selectedNation?.value !== 'None' && selectedNation?.value) {
      return selectedNation?.label ?? ''
    }

    return ''
  }

  render() {
    const { selectedRouteStatsType, selectedSr, selectedRoute, selectedTownStatsType, selectedDh, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation, srOrders, visitDistanceThreshold } = this.props

    let stats = this.props.stats ?? {}
    stats = this._reCalculateStats(stats, visitDistanceThreshold)

    // Get Common Stats Title
    const commonStatsTitle = this._getCommonStatsTitle(selectedRouteStatsType, selectedSr, selectedRoute, selectedTownStatsType, selectedDh, selectedTown, selectedTerritory, selectedArea, selectedRegion, selectedNation)

    return(
      <Box sx={ containerStyles }>
        <Box sx={{ ...bodyContainerStyles, maxHeight: `${ document.querySelector('.right-panel-body')?.clientHeight - 32 }px` }}>
          <CommonStats
            title={ commonStatsTitle }
            stats={ stats }
            helpTexts={{
              visited_outlets: `Number of outlets where distance between SO order and Outlet is within ${ srOrders?.length > 0 ? visitDistanceThreshold : 50 }m`,
              brand_per_call: 'Number of overall brands ordered per outlet visit'
            }}
          />
          { Object.keys(stats)?.length > 0 &&
            <Box sx={ exportActionContainerStyles }>
              <ExportMenu
                buttonId='stats-export-button'
                buttonText='Export Stats'
                menuId='stats-export-button-menu'
                disabled={ !Object.keys(stats)?.length }
                onExportCsv={ this._onExportCsv }
                onExportXlsx={ this._onExportXlsx }
              />
            </Box>
          }

          { (selectedRoute?.value !== 'None' && selectedRoute?.value !== 'All' && selectedRouteStatsType === 'sr' && selectedSr?.value !== 'None' && selectedSr?.value !== 'All') &&
            <React.Fragment>
              <Divider sx={{ my: '0.8rem', width: '100%' }} />
              <SrOrdersTable />
            </React.Fragment>
          }
        </Box>
      </Box>
    )
  }
}

// Styles
const containerStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between'
}

const bodyContainerStyles = {
  padding: '0.5rem 1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'space-between',
  overflow: 'auto'
}

const exportActionContainerStyles = {
  padding: '1rem 0px',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}

// Prop Types
Stats.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedBrand: PropTypes.object,
  selectedSku: PropTypes.object,
  selectedRouteStatsType: PropTypes.string,
  selectedSr: PropTypes.object,
  selectedTownStatsType: PropTypes.string,
  selectedDh: PropTypes.object,
  stats: PropTypes.object,
  selectedRoute: PropTypes.object,
  selectedTown: PropTypes.object,
  selectedTerritory: PropTypes.object,
  selectedArea: PropTypes.object,
  selectedRegion: PropTypes.object,
  selectedNation: PropTypes.object,
  srOrders: PropTypes.array,
  visitDistanceThreshold: PropTypes.number,
  dispatch: PropTypes.func
}

Stats.defaultProps = {
  startDate: '',
  endDate: '',
  selectedBrand: null,
  selectedSku: null,
  selectedRouteStatsType: '',
  selectedSr: null,
  selectedTownStatsType: '',
  selectedDh: null,
  stats: {},
  selectedRoute: null,
  selectedTown: null,
  selectedTerritory: null,
  selectedArea: null,
  selectedRegion: null,
  selectedNation: null,
  srOrders: [],
  visitDistanceThreshold: 50,
  dispatch: () => null
}

const mapStateToProps = state => ({
  startDate: state?.stat?.startDate ?? '',
  endDate: state?.stat?.endDate ?? '',
  selectedBrand: state?.stat?.selectedBrand ?? null,
  selectedSku: state?.stat?.selectedSku ?? null,
  selectedRouteStatsType: state?.stat?.selectedRouteStatsType ?? '',
  selectedSr: state?.stat?.selectedSr ?? null,
  selectedTownStatsType: state?.stat?.selectedTownStatsType ?? '',
  selectedDh: state?.stat?.selectedDh ?? null,
  stats: state?.stat?.stats ?? {},
  selectedRoute: state?.nav?.selectedRoute ?? null,
  selectedTown: state?.nav?.selectedTown ?? null,
  selectedTerritory: state?.nav?.selectedTerritory ?? null,
  selectedArea: state?.nav?.selectedArea ?? null,
  selectedRegion: state?.nav?.selectedRegion ?? null,
  selectedNation: state?.nav?.selectedNation ?? null,
  srOrders: state?.stat?.srOrders ?? [],
  visitDistanceThreshold: state?.configs?.visitDistanceThreshold ?? 50
})

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Stats)