import { ConfigsActionTypes } from '../actions/actionTypes'

const initialState = {
  visitDistanceThreshold: 50,
  fastOrderDuration: 120,
  lateOrderThresholdTime: '17:00:00'
}

const configsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ConfigsActionTypes.SET_VISIT_DISTANCE_THRESHOLD:
      return {
        ...state,
        visitDistanceThreshold: action.payload
      }
    
    case ConfigsActionTypes.SET_FAST_ORDER_DURATION:
      return {
        ...state,
        fastOrderDuration: action.payload
      }
    
    case ConfigsActionTypes.SET_LATE_ORDER_THRESHOLD_TIME:
      return {
        ...state,
        lateOrderThresholdTime: action.payload
      }
      
    default:
      return state
  }
}

export default configsReducer